import { useParams } from 'react-router-dom';
import { LoginComponent } from './components/Account/LoginComponent';
import { RegisterComponent } from './components/Account/RegisterComponent';
import ApiAuthorzationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { Company } from './components/Company/Company';
import Home from "./components/Home";
import { LayoutBox } from './components/LayoutBox';
import { Companies } from './components/Company/Companies';
import { ResetComponent } from './components/Account/ResetComponent';
import { Users } from './components/Users/Users';
import { User } from './components/Users/User';
import { Dashboard } from './components/Dashboard';
import SupportAdminComponent from './components/Support/SupportAdminComponent';
import SupportComponent from './components/Support/SupportComponent';
import ErrorPage from './components/ErrorPage/ErrorPage';
import { Attendance } from './components/Attendance/Attendance';
import { Attendances } from './components/Attendance/Attendances';
import { Staffs } from './components/Staff/Staffs';
import { Staff } from './components/Staff/Staff';
import { Projects } from './components/Project/Projects';
import { Project } from './components/Project/Project';
import { RegisterAdminComponent } from './components/Account/RegisterAdminComponent';
import AppConfig from './AppConfig';
import BarcodeScanner from './components/Scanner/BarcodeScanner';
import Html5QrcodePlugin from './components/Scanner/Html5QrcodeScannerPlugin';

const AppRoutes = [
  {
    path: '*',
    element: <ErrorPage ErrorMessage={'Page Not found!'} />,
    // requireAuth: false,
  },
  {
    index: true,
    element: <Home />,
    requireAuth: true,
  },
  {
    path: '/authorize/support',
    requireAuth: true,
    role: [AppConfig.roles.admin],
    element: <div className='details-box card shadow p-0' >
          <SupportAdminComponent />
      </div>
  },
  {
    path: '/scan',
    element: <div className='details-box card shadow p-0 text-white' >
          <Html5QrcodePlugin />
      </div>
  },
  {
    path: '/scan1',
    element: <div className='details-box card shadow p-0 text-white' >
          <BarcodeScanner />
      </div>
  },
  {
    path: '/support',
    element: <div className='details-box card shadow p-0 text-white' >
          <SupportComponent />
      </div>
  },
  {
    path: '/authorize/register-admin',
    requireAuth: true,
    role: [AppConfig.roles.admin, AppConfig.roles.staff],
    element: <LayoutBox title='Registration' 
      subTitle='Register An Admin'>
          <RegisterAdminComponent />
      </LayoutBox>
  },
  {
    path: '/authorize/register',
    element: <LayoutBox title='Registration' 
      subTitle='Fill the form to proceed'>
          <RegisterComponent />
      </LayoutBox>
  },
  {
    path: '/authorize/reset',
    element: <LayoutBox title='Reset Password' 
      subTitle='Fill the form to proceed'>
          <ResetComponent />
      </LayoutBox>
  },
  {
    path: '/authorize/login',
    element: <LayoutBox title='Login' 
      subTitle='Fill the form to proceed'>
          <LoginComponent />
      </LayoutBox>
  },
  {
    path: '/authorize/users',
    requireAuth: true,
    role: [AppConfig.roles.admin],
    element: <Users />
  },
  {
    path: '/user/:id',
    requireAuth: false,
    element: <GetUser />
  },
  {
    path: '/authorize/users/edit/:id',
    requireAuth: true,
    role: [AppConfig.roles.admin],
    element: <GetUserEdit />
  },
  {
    path: '/authorize/profile',
    requireAuth: true,
    element: <User edit={true}/>
  },
  {
    path: '/authorize/dashboard',
    requireAuth: true,
    element: <Dashboard edit={true}/>
  },
  {
    path: '/authorize/attendances',
    requireAuth: true,
    role: [AppConfig.roles.admin, AppConfig.roles.staff],
    element: <Attendances />
  },
  {
    path: '/authorize/attendance',
    requireAuth: true,
    role: [AppConfig.roles.admin, AppConfig.roles.staff],
    element: <Attendance />
  },
  {
    path: '/authorize/projects',
    requireAuth: true,
    role: [AppConfig.roles.admin],
    element: <Projects />
  },
  {
    path: '/authorize/project',
    requireAuth: true,
    role: [AppConfig.roles.admin],
    element: <Project edit={true}/>
  },
  {
    path: '/authorize/project/edit/:id',
    requireAuth: true,
    role: [AppConfig.roles.admin],
    element: <GetProjectEdit />
  },
  {
    path: '/authorize/project/:id',
    requireAuth: true,
    role: [AppConfig.roles.admin],
    element: <GetProject />
  },
  {
    path: '/authorize/companies',
    requireAuth: true,
    role: [AppConfig.roles.admin, AppConfig.roles.staff],
    element: <Companies />
  },
  {
    path: '/authorize/company',
    requireAuth: true,
    role: [AppConfig.roles.admin, AppConfig.roles.staff],
    element: <Company edit={true}/>
  },
  {
    path: '/authorize/company/edit/:id',
    requireAuth: true,
    role: [AppConfig.roles.admin, AppConfig.roles.staff],
    element: <GetCompanyEdit />
  },
  {
    path: '/authorize/company/:id',
    requireAuth: true,
    role: [AppConfig.roles.admin, AppConfig.roles.staff],
    element: <GetCompany />
  },
  {
    path: '/authorize/staffs',
    requireAuth: true,
    role: [AppConfig.roles.admin, AppConfig.roles.staff],
    element: <Staffs />
  },
  {
    path: '/authorize/staff',
    requireAuth: true,
    role: [AppConfig.roles.admin, AppConfig.roles.staff],
    element: <Staff edit={true}/>
  },
  {
    path: '/authorize/staff/edit/:id',
    requireAuth: true,
    role: [AppConfig.roles.admin, AppConfig.roles.staff],
    element: <GetStaffEdit />
  },
  {
    path: '/authorize/staff/:id',
    requireAuth: true,
    role: [AppConfig.roles.admin, AppConfig.roles.staff],
    element: <GetStaff />
  },
  // {
  //   path: '/fetch-data',
  //   requireAuth: true,
  //   element: <FetchData />
  // },
  ...ApiAuthorzationRoutes
];

function GetUser() {
  const { id } = useParams();
  return (<User _Id={id} edit={false}/>);
}

function GetUserEdit() {
  const { id } = useParams();
  return (<User _Id={id} edit={true}/>);
}

function GetProject() {
  const { id } = useParams();
  return (<Project _Id={id} edit={false}/>);
}

function GetProjectEdit() {
  const { id } = useParams();
  return (<Project _Id={id} edit={true}/>);
}

function GetCompany() {
  const { id } = useParams();
  return (<Company _Id={id} edit={false}/>);
}

function GetCompanyEdit() {
  const { id } = useParams();
  return (<Company _Id={id} edit={true}/>);
}

function GetStaff() {
  const { id } = useParams();
  return (<Staff _Id={id} edit={false}/>);
}

function GetStaffEdit() {
  const { id } = useParams();
  return (<Staff _Id={id} edit={true}/>);
}

export default AppRoutes;
