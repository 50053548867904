import React, { Component } from 'react';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import { ToastContainer } from 'react-toastify';

export class Layout extends Component {
  static displayName = Layout.name;

  componentDidMount(){
    var el = document.getElementById('main-container');
    el.onclick=(()=>{
      var sideNav = document.getElementById('side-nav');
      if(!sideNav.classList.contains("close"))
        sideNav.classList.toggle("close");
    })
  }
  render() {
    return (
      <div>
        <NavMenu />
        <ToastContainer />
        <Container id="main-container">
          {this.props.children}
        </Container>
      </div>
    );
  }
}
