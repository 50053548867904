import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import * as XLSX from "xlsx";
import AppConfig from "../AppConfig";
import { toast } from "react-toastify";

const ExcelToJsonConverter = () => {
  const [jsonData, setJsonData] = useState([]);
  const [replacePast, setReplacePast] = useState(false);
  const [upLoading, setUpLoading] = useState(false);

  const onDrop = (acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const excelData = XLSX.utils.sheet_to_json(worksheet);

      // Map Excel columns to JSON keys
      const jsonKeys = {
        "ID Number": "id",
        "Civil ID Number": "civilId",
        Name: "name",
        الاسم: "nameLocal",
        Company: "companyName",
      };

      const convertedData = excelData.map((row) => {
        const convertedRow = {};
        Object.keys(row).forEach((key) => {
          const jsonKey = jsonKeys[key];
          if (jsonKey) {
            convertedRow[jsonKey] = row[key];
          }
        });
        return convertedRow;
      });

      // setJsonData(convertedData);
      setJsonData(removeDuplicates(convertedData));
    };

    reader.readAsArrayBuffer(file);
  };

  const handleSwitchChange = () => {
    setReplacePast(!replacePast);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const submitData = async () => {
    if (AppConfig.getAccessRole() !== "admin") return;

    if (jsonData === null) {
      toast("Data is required!");
      return;
    }

    setUpLoading(true);

    var url = "staffs_multiple/create";
    var method = "POST";

    const body = JSON.stringify({
      replacePast: replacePast,
      staffData: jsonData,
    });

    var result = await AppConfig.PostData(url, method, body);
    if (result["exception"] !== undefined || result["error"] !== undefined) {
      toast.error(result["error"]);
    } else if (
      result["result"] === "success" ||
      result["result"] === true ||
      result["data"] !== undefined
    ) {
      toast(AppConfig.messages.success);
      setJsonData([]);
    } else {
      toast(result["message"]);
    }
    setUpLoading(false);
  };

  const removeDuplicates = (data) => {
    const uniqueIds = new Set();
    return data.filter((item) => {
      const id = item.id;
      const civilId = item.civilId;
      // Check if both "id" and "civilId" are unique
      if (!uniqueIds.has(id) && !uniqueIds.has(civilId)) {
        uniqueIds.add(id);
        uniqueIds.add(civilId);
        return true;
      }
      return false;
    });
  };

  if(AppConfig.getAccessRole() !== "admin"){return <></>}

  return (
    <div className="row gap-2">
      <div className="col-md-12">
        {jsonData.length > 0 && (
          <div className="row p-4 pb-0">
            <div className="col-md-3 col-xl-2">
              <Form.Check
                type="switch"
                className="custom-switch"
                label={
                  replacePast
                    ? "Replace existing records."
                    : "Skip existing records."
                }
                id="replacePastSwitch"
                checked={replacePast}
                onChange={handleSwitchChange}
              />
            </div>
            <div className="col-md-3 col-xl-2">
              {!upLoading && (
                <button
                  className="btn btn-success"
                  onClick={(e) => {
                    submitData();
                  }}
                >
                  <i className="fa fa-save me-2"></i>
                  <span>Save Changes</span>
                </button>
              )}
              {upLoading && (
                <button disabled className="btn btn-primary">
                  <i className="fa fa-spinner fa-spin me-2"></i>
                  Saving Changes...
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="col-md-12">
        <div
          {...getRootProps()}
          style={{ border: "1px solid black", padding: "20px", margin: "20px" }}
        >
          <input {...getInputProps()} />
          <p>Drag & drop Excel file here, or click to select file</p>
        </div>

        <pre className="p-4 mb-4 border-bottom">
          {/* {JSON.stringify(jsonData, null, 2)} */}
          {JSON.stringify(removeDuplicates(jsonData), null, 2)}
        </pre>
      </div>
    </div>
  );
};

export default ExcelToJsonConverter;
