import React, { useEffect, useState, useRef } from "react";
import { Collapse, Container, NavbarBrand, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import LoginMenu from "./api-authorization/LoginMenu";
import "./NavMenu.css";
import { RegisterComponent } from "./Account/RegisterComponent";
import { ApplicationPaths } from "./api-authorization/ApiAuthorizationConstants";
import logo from "../images/logo.jpg";
import { LoginComponent } from "./Account/LoginComponent";
import AppConfig from "../AppConfig";
import { useDispatch, useSelector } from "react-redux";
import { fetchUser } from "../redux/users/userSlice";

export default function NavMenu() {
  // userName: user && user.name
  const user = useSelector((state) => state.user.user);
  const authenticated = useSelector((state) => state.user.isAuthenticated);
  const [collapsed] = useState(true);
  // const [userName, setUserName] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const dispatch = useDispatch();
  const attendanceRef = useRef(null);
  const projectRef = useRef(null);
  const companyRef = useRef(null);
  const staffRef = useRef(null);

  const toggleDropDown = (ref) => {
    ref.current.classList.toggle("dropdown-menu");
  };

  useEffect(() => {
    dispatch(fetchUser());
  }, [dispatch]);

  useEffect(() => {
    setIsAuthenticated(authenticated);
  }, [authenticated]);

  // useEffect(() => {
  //   if(user===null) return;
  //   setUserName(user?.name);
  // }, [user]);

  return (
    <React.Fragment>
      <div
        className="modal fade modal-bg show"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog shadow">
          <div className="modal-content border shadow">
            <div className="modal-header">
              <h5 className="modal-title text-light" id="exampleModalLabel">
                ANAKINGS HOMES AND PROPERTIES
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <ul className="nav nav-pills nav-fill">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="login-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#login"
                    aria-current="page"
                    href={"#"}
                  >
                    Login
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="#"
                    id="register-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#register"
                  >
                    Register
                  </a>
                </li>
              </ul>
              <hr className="hr" />
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="login"
                  role="tabpanel"
                  aria-labelledby="login-tab"
                >
                  <LoginComponent />
                </div>
                <div
                  className="tab-pane fade"
                  id="register"
                  role="tabpanel"
                  aria-labelledby="register-tab"
                >
                  <RegisterComponent />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <header>
        <nav className="close bg-light" id="side-nav">
          <div className="logo-name">
            <div className="logo-image">
              {/* <img src={logo} alt="" className="rounded bg-light sitelogo" /> */}
              <img src={AppConfig.baseAPIUrl + 'settings/get_logo'} alt="" className="rounded bg-light sitelogo" />
            </div>

            <span className="logo_name" hidden>
              {AppConfig.AppName}
            </span>
            <i
              className="h5 fa fa-window-close sidebar-toggle1"
              onClick={() => {
                document.getElementById("side-nav").classList.toggle("close");
              }}
            ></i>
          </div>

          <div className="menu-items">
            <ul className="nav-links ps-0">
              {!isAuthenticated && (
                <React.Fragment>
                  <li>
                    <a href="/authorize/attendances">
                      <i className="fa fa-info-circle"></i>
                      <span className="link-name">Support</span>
                    </a>
                  </li>
                  <li>
                    <a
                      href={ApplicationPaths.Login}
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      <i className="fa fa-user"></i>
                      <span className="link-name">Login</span>
                    </a>
                  </li>
                  <li>
                    <a href={ApplicationPaths.Register}>
                      <i className="fa fa-user-plus"></i>
                      <span className="link-name">Register</span>
                    </a>
                  </li>
                </React.Fragment>
              )}
              {isAuthenticated && (
                <React.Fragment>
                  <li>
                    <a href="/authorize/dashboard">
                      <i className="fa fa-home"></i>
                      <span className="link-name">Dashboard</span>
                    </a>
                  </li>
                  {AppConfig.getAccessRole() !== "admin" && (
                    <>
                      <li>
                        <a href="/authorize/attendance">
                          <i className="fa fa-list"></i>
                          <span className="link-name">Attendance</span>
                        </a>
                      </li>
                    </>
                  )}
                  {(AppConfig.getAccessRole() === "admin" || AppConfig.getAccessRole() === "staff") && (
                    <>
                      <li>
                        <a
                          href="#Attendances"
                          className="dropdown"
                          onClick={(e) => {
                            e.preventDefault();
                            toggleDropDown(attendanceRef);
                          }}
                        >
                          <i className="fa fa-play-circle"></i>
                          <span className="link-name">Attendances</span>
                        </a>
                        <div class="dropdown-menu" ref={attendanceRef}>
                          <a class="dropdown-item" href="/authorize/attendance">
                            New Attendances
                          </a>
                          <a
                            class="dropdown-item"
                            href="/authorize/attendances"
                          >
                            View Attendances
                          </a>
                        </div>
                      </li>
                      <li>
                        <a
                          href="#Staffs"
                          className="dropdown"
                          onClick={(e) => {
                            e.preventDefault();
                            toggleDropDown(staffRef);
                          }}
                        >
                          <i className="fa fa-list"></i>
                          <span className="link-name">Staffs</span>
                        </a>
                        <div class="dropdown-menu" ref={staffRef}>
                          <a class="dropdown-item" href="/authorize/staff">
                            New Staff
                          </a>
                          <a class="dropdown-item" href="/authorize/staffs">
                            View Staffs
                          </a>
                          {/* <div class="dropdown-divider"></div>
      <a class="dropdown-item" href="#">Separated link</a> */}
                        </div>
                      </li>
                      <li>
                        <a
                          href="#Companies"
                          className="dropdown"
                          onClick={(e) => {
                            e.preventDefault();
                            toggleDropDown(companyRef);
                          }}
                        >
                          <i className="fa fa-list"></i>
                          <span className="link-name">Companies</span>
                        </a>
                        <div class="dropdown-menu" ref={companyRef}>
                          <a class="dropdown-item" href="/authorize/company">
                            New Company
                          </a>
                          <a class="dropdown-item" href="/authorize/companies">
                            View Companies
                          </a>
                          {/* <div class="dropdown-divider"></div><a class="dropdown-item" href="#">Separated link</a> */}
                        </div>
                      </li>
                      {AppConfig.getAccessRole() === "admin" && (<li>
                        <a
                          href="#Projects"
                          className="dropdown"
                          onClick={(e) => {
                            e.preventDefault();
                            toggleDropDown(projectRef);
                          }}
                        >
                          <i className="fa fa-list"></i>
                          <span className="link-name">Projects</span>
                        </a>
                        <div class="dropdown-menu" ref={projectRef}>
                          <a class="dropdown-item" href="/authorize/project">
                            New Project
                          </a>
                          <a class="dropdown-item" href="/authorize/projects">
                            View Projects
                          </a>
                          {/* <div class="dropdown-divider"></div><a class="dropdown-item" href="#">Separated link</a> */}
                        </div>
                      </li>)}
                      <li>
                        <a href="/authorize/register-admin">
                          <i className="fa fa-key"></i>
                          <span className="link-name">Register Admin Users</span>
                        </a>
                      </li>
                      {AppConfig.getAccessRole() === "admin" && (<li>
                        <a href="/authorize/users">
                          <i className="fa fa-users"></i>
                          <span className="link-name">Users</span>
                        </a>
                      </li>)}
                    </>
                  )}
                </React.Fragment>
              )}
            </ul>

            <ul className="logout-mode">
              {isAuthenticated && (
                <React.Fragment>
                  <li>
                    <a href={ApplicationPaths.Profile}>
                      <i className="fa fa-info-circle"></i>
                      <span className="link-name">Profile</span>
                    </a>
                  </li>
                  <li>
                    <a href={ApplicationPaths.LogOut}>
                      <i className="fa fa-key"></i>
                      <span className="link-name">Logout</span>
                    </a>
                  </li>
                </React.Fragment>
              )}
            </ul>
          </div>
        </nav>
      </header>
      <div
        className="navbar navbar-expand-sm navbar-toggleable-sm  navbar-dark bg-primary border-bottom box-shadow mb-3"
        light
      >
        <Container>
          <div className="col-md-4">
            <i
              className="h5 fa fa-bars text-light sidebar-toggle1 mt-2"
              onClick={() => {
                document.getElementById("side-nav").classList.toggle("close");
              }}
            ></i>
            <NavbarBrand tag={Link} to="/">
              {/* <img src={logo} alt="" className="rounded bg-light sitelogo" /> */}
              <img src={AppConfig.baseAPIUrl + 'settings/get_logo'} alt="" className="rounded bg-light sitelogo" />
              {/* {AppConfig.AppName} */}
            </NavbarBrand>
            {/* <NavbarToggler onClick={this.toggleNavbar} className="mr-2" /> */}
          </div>
          <Collapse
            className="col-md-8 d-sm-inline-flex flex-sm-row-reverse"
            isOpen={!collapsed}
            navbar
          >
            <ul className="navbar-nav flex-grow">
              <NavItem>
                <NavLink tag={Link} to="/">
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/authorize/attendances">
                  Attendances
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink tag={Link} to="/support">
                  Support
                </NavLink>
              </NavItem>
              {/* <LoginMenu isAuthenticated={isAuthenticated} userName={userName}/> */}
              {/* <LoginMenu userName={userName}/> */}
              <LoginMenu />
            </ul>
          </Collapse>
        </Container>
      </div>
    </React.Fragment>
  );
}