import React, { Component } from "react";
import CustomAlert from "../custom/custom_alert";
import AppConfig from "../../AppConfig";
import { toast } from "react-toastify";
import { LoaderFull } from "../LoaderFull";

export class Project extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      edit: props.edit === true,
      loading: false,
      upLoading: false,
      obj: {},
      _Id: "",
      msg: "",
      msgColor: AppConfig.messageColors.success,
    };
  }

  componentDidMount() {
    this.populateData();
  }
  async populateData() {
    if (this.props._Id === undefined || this.props._Id === null) return;
    this.setState({
      loading: true,
    });
    var result = await AppConfig.PostData("projects/" + this.props._Id, "GET");
    //  console.log(result)
    this.setState({
      loading: false,
    });
    if (result["error"] !== undefined) {
      toast.error(result["error"]);
    } else if (result["data"] === undefined) {
      toast.warn("No result found");
    } else {
      this.setState({
        obj: result["data"],
      });
    }
  }

  async submitData() {
    if (AppConfig.getAccessRole() !== "admin") return;
    this.setState({ msgColor: AppConfig.messageColors.warning });
    if (this.state.obj.name === "") {
      this.setState({ msg: "Name is required!" });
      return;
    }

    this.setState({
      upLoading: true,
    });

    var url =
      "projects" +
      (this.props._Id === undefined || this.props._Id === null
        ? ""
        : "/" + this.props._Id);
    var method =
      this.props._Id === undefined || this.props._Id === null ? "POST" : "PUT";

    const body = JSON.stringify({
      name: this.state.obj.name,
    });

    var result = await AppConfig.PostData(url, method, body);
    if (result["exception"] !== undefined || result["error"] !== undefined) {
      toast.error(result["error"]);
    } else if (
      result["result"] === "success" ||
      result["result"] === true ||
      result["data"] !== undefined
    ) {
      toast(result["message"]);
      if (this.props._Id === undefined) {
        var obj = this.state.obj;
        obj.name = "";
        this.setState({ obj });
      }
    } else {
      toast(result["message"]);
    }
    this.setState({
      upLoading: false,
    });
  }

  handleDetailsChange = (input, e) => {
    var obj = this.state.obj;
    obj[input] = e.target.value;
    this.setState({ obj });
  };

  renderData(obj) {
    return (
      <form
        onSubmit={(e) => {
          e.preventDefault();
          this.submitData();
        }}
      >
        <fieldset id="fieldset" className="p-4 col-md-4" ref={this.formRef} disabled={!this.state.edit}>
          <div className="row">
            <div className="form-group">
              <label className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                aria-label="Name"
                autoFocus
                aria-describedby="basic-addon1"
                value={obj.name}
                onChange={(e) => this.handleDetailsChange("name", e)}
              />
            </div>
            {!this.state.edit && this.props._Id !== undefined && (
              <div className="row">
                <div className="col-md-6 p-2">
                  <label className="form-label">Active</label>
                  <div className="input-group mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        {obj.status && (
                          <i className="fa fa-check text-success"></i>
                        )}
                        {!obj.status && (
                          <i className="fa fa-close text-danger"></i>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </fieldset>

        {this.state.msg !== "" && (
          <CustomAlert
            msg={this.state.msg}
            type={this.state.msgColor}
            Remove={() => {
              this.setState({ msg: "" });
            }}
          />
        )}
        <div className="card-footer p-4">
          {!this.state.upLoading &&
            ((this.props._Id !== null && this.state.edit === true) ||
              this.props._Id === undefined) && (
              <button className="btn btn-success col-md-3" type="submit">
                <i className="fa fa-save me-2"></i>
                {this.props._Id === undefined && <span>Save Changes</span>}
                {this.props._Id !== undefined && <span>Update Item</span>}
              </button>
            )}
          {this.state.upLoading && (
            <button disabled className="btn btn-primary col-md-3">
              <i className="fa fa-spinner fa-spin me-2"></i>
              Saving Changes...
            </button>
          )}
          <div className="mt-2 pt-2 border-top">
            {this.props._Id != null && this.state.edit !== true && (
              <a
                href={"authorize/project/edit/" + this.props._Id}
                className="btn btn-sm btn-primary rounded m-2"
              >
                <i className="fa fa-edit me-2"></i>
                Edit Project
              </a>
            )}
            {this.props._Id !== undefined && (
              <a
                href="authorize/project"
                className="btn btn-sm btn-primary rounded m-2"
              >
                <i className="fa fa-plus me-2"></i>
                New Project
              </a>
            )}
            <a
              href="authorize/projects"
              className="btn btn-sm btn-primary rounded m-2"
            >
              <i className="fa fa-close me-2"></i>
              Back to Projects
            </a>
          </div>
        </div>
      </form>
    );
  }

  render() {
    let contents = this.state.loading ? (
      <LoaderFull />
    ) : (
      this.renderData(this.state.obj)
    );

    return (
      <div>
        <div
          className="row justify-content-center"
          aria-labelledby="tabelLabel"
        >
          <div className="details-box col-md-6 card shadow p-0">
            <h4 className="card-header text-warning">
              <div className="w-100 d-flex justify-content-end gap-2">
                <label>
                  <a
                    href="authorize/projects"
                    className="btn btn-sm btn-primary"
                  >
                    <i className="fa fa-close"></i>
                  </a>
                </label>
              </div>
              <div>
                {this.props._Id === undefined
                  ? "Add New Project"
                  : this.state.edit === true
                  ? "Update Project"
                  : "Project Details"}
              </div>
            </h4>
            <div className="p-0">
              {(this.state.edit === true ||
                this.props._Id === undefined ||
                this.props._Id === null) && (
                <p className="p-4">Enter project details below.</p>
              )}
              <p className="border-bottom p-4"></p>
              {contents}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
