import React, { useState } from "react";
import AppConfig from "../../AppConfig";
import authService from "../api-authorization/AuthorizeService";
// import {Navigate } from 'react-router-dom'
// import auth from '../auth/auth';

export const LoginComponent = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [message, setMessage] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [isLoading, setIsLoading] = useState(false);

  const togglePWD = (e) => {
    // toggle the type attribute
    const pwd = document.getElementById("Password");
    if (pwd == null) return;
    const newType = passwordType === "password" ? "text" : "password";
    setPasswordType(newType);
    // toggle the eye icon
    e.className = passwordType === "password" ? "fa fa-eye-slash" : "fa fa-eye";
  };

  const validatePassword = (e) => {
    var validated = true;
    if (e.value.length < 8) validated = false;
    else if (!/\d/.test(e.value)) validated = false;
    else if (!/[a-z]/.test(e.value)) validated = false;
    else if (!/[A-Z]/.test(e.value)) validated = false;
    else if (/[a-zA-Z0-9]{8,}/.test(e.value)) validated = false;

    setMessage("");
    e.style.borderColor = "green";
    if (!validated) {
      // setMessage("Password must contain at least one digit, At least one lower case, At least one upper case, At least 8 from the mentioned characters");
      setMessage("Password must pass validation");
      e.style.borderColor = "red";
    }
    return validated;
  };
  const handleSubmit = (btn) => {
    if (/[a-zA-Z0-9]{8,}/.test(password)) {
      setMessage("Invalid password.");
      return;
    }
    setIsLoading(true);
    var userData = [];
    userData = JSON.stringify({
      name: username,
      password: password,
    });
    // userData = username.includes("@") ? JSON.stringify({
    //     "email": username,
    //     "password": password
    // }) : JSON.stringify({
    //     "name": username,
    //     "password": password
    // });
    //console.log(userData);
    (async () => {
      try {
        var result = await AppConfig.PostData(
          `account/login`,
          "POST",
          userData
        );
        // console.log(result);
        setIsLoading(false);
        if (result["error"] !== null && result["error"] !== undefined) {
          setMessage(result["error"]);
        } else {
          if (result["token"] !== null && result["token"] !== undefined) {
            setMessage("Successfull Login");
            localStorage.setItem("access_token", result["token"]);
            localStorage.setItem("role", result["role"]);
            localStorage.setItem("email", result["email"]);
            localStorage.setItem("name", result["name"]);
            localStorage.setItem("id", result["id"]);
            sessionStorage.setItem("last_login", JSON.stringify(new Date()));
            authService.signIn();
            const redirectUrl = sessionStorage.getItem("redirectUrl");
            if (result["role"] !== "admin") {
              window.location.href = window.location.origin;
            } else if (redirectUrl != null) {
              if (redirectUrl.endsWith("undefined")) {
                window.location.href = window.location.origin;
              } else {
                window.location.href = redirectUrl;
              }
              sessionStorage.removeItem("redirectUrl");
            } else {
              window.location.href = "/authorize/dashboard";
            }
            setRedirect(true);
            return;
          } else {
            setMessage(result["message"]);
            return;
          }
        }
      } catch (error) {
        setMessage(error.toString().replace("TypeError: ", ""));
      }
    })();

    if (redirect) {
      window.location.href = "/Dashboard";
      // return <Navigate to='/Dashboard' replace />
    }
  };

  return (
    <React.Fragment>
      <form className="col-md-4 col-lg-3">
        <div className="form-group">
          <label className="control-label lbl">Email/Username</label>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              <i className="fa fa-envelope"></i>
            </span>
            <input
              required
              className="form-control"
              type="text"
              placeholder="Enter Email/Username"
              defaultValue={username}
              autoFocus
              onChange={(e) => {
                setUsername(e.target.value);
                // if(e.target.value === 'Administrator'){
                //     e.target.type = 'text';
                // }else{
                //     e.target.type = 'email'
                // }
              }}
              aria-describedby="basic-addon1"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label lbl">Password</label>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              <i className="fa fa-key"></i>
            </span>
            <input
              required
              className="form-control"
              type={passwordType}
              placeholder="Enter Password"
              id="Password"
              defaultValue={password}
              onChange={(e) => {
                setPassword(e.target.value);
                validatePassword(e.target);
              }}
              aria-describedby="basic-addon1"
            />
            <span className="input-group-text">
              <i
                className="fa fa-eye-slash"
                onClick={(e) => {
                  togglePWD(e.target);
                }}
              ></i>
            </span>
          </div>
        </div>
        <hr className="hr" />
        <div className="form-group text-center">
          {isLoading && (
            <button type="button" className="btn btn-primary btn-5">
              <i className="fa fa-spinner fa-spin"></i> Submiting...
            </button>
          )}
          {!isLoading && (
            <button
              type="submit"
              className="btn btn-primary btn-5"
              onClick={(e) => {
                e.preventDefault();
                return handleSubmit(this);
              }}
            >
              Login
            </button>
          )}
          <div className="text-danger">{message}</div>
          <div>
            <a href="authorize/register">Register instead?</a>
            <i> | </i>
            <a href="authorize/reset">Forgot Password?</a>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};
