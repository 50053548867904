import React, { Component, Fragment, useEffect, useState } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import AppConfig from '../../AppConfig';
import { useSelector } from 'react-redux';

// export default function LoginMenu ({isAuthenticated = false, userName = ''}) {
// export default function LoginMenu ({userName = ''}) {
export default function LoginMenu () {
  // const userName = useSelector((state) => state.user.user && state.user.name);
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const [userName, setUserName] = useState('');
  
  useEffect(() => {
    const name = AppConfig.getAccessUser();
    setUserName(name);
  }, []);

  const getDisplayName = () => {
    console.log(userName);
    if(userName === null || userName === undefined) return '';
    if(userName.length <= 15) return userName;
    return userName.substring(0, 15) + '..';
  }
  const authenticatedView = (profilePath, logoutPath, logoutState) => {
    return (<Fragment>
      <NavItem>
        <NavLink tag={Link} to={profilePath}>
          <i class="fa fa-user-circle me-2"></i>
          {AppConfig.Capitalize(getDisplayName(AppConfig.getAccessUser()))}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink replace tag={Link} to={logoutPath} state={logoutState}>Logout</NavLink>
      </NavItem>
    </Fragment>);
  }

  const anonymousView = (registerPath, loginPath) => {
    return (<Fragment>
      <NavItem>
        <NavLink tag={Link} to={registerPath}>Register</NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} to={loginPath}>Login</NavLink>
      </NavItem>
    </Fragment>);
  }

  if (!isAuthenticated || userName === null) {
    const registerPath = `${ApplicationPaths.Register}`;
    const loginPath = `${ApplicationPaths.Login}`;
    return anonymousView(registerPath, loginPath);
  } else {
    const profilePath = `${ApplicationPaths.Profile}`;
    const logoutPath = `${ApplicationPaths.LogOut}`;
    const logoutState = { local: true };
    return authenticatedView(profilePath, logoutPath, logoutState);
  }
}

export class LoginMenuOld extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      userName: null
    };
  }

  componentDidMount() {
    this._subscription = authService.subscribe(() => this.populateState());
    this.populateState();
  }

  componentWillUnmount() {
    authService.unsubscribe(this._subscription);
  }

  async populateState() {
    const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
    this.setState({
      isAuthenticated,
      userName: user && user.name
    });
  }

  render() {
    const { isAuthenticated, userName } = this.state;
    if (!isAuthenticated) {
      const registerPath = `${ApplicationPaths.Register}`;
      const loginPath = `${ApplicationPaths.Login}`;
      return this.anonymousView(registerPath, loginPath);
    } else {
      const profilePath = `${ApplicationPaths.Profile}`;
      const logoutPath = `${ApplicationPaths.LogOut}`;
      const logoutState = { local: true };
      return this.authenticatedView(userName, profilePath, logoutPath, logoutState);
    }
  }

  authenticatedView(userName, profilePath, logoutPath, logoutState) {
    return (<Fragment>
      <NavItem>
        <NavLink tag={Link} to={profilePath}>
          <i class="fa fa-user-circle me-2"></i>
          {AppConfig.Capitalize(AppConfig.getAccessUser())}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink replace tag={Link} to={logoutPath} state={logoutState}>Logout</NavLink>
      </NavItem>
    </Fragment>);
  }

  anonymousView(registerPath, loginPath) {
    return (<Fragment>
      <NavItem>
        <NavLink tag={Link} to={registerPath}>Register</NavLink>
      </NavItem>
      <NavItem>
        <NavLink tag={Link} to={loginPath}>Login</NavLink>
      </NavItem>
    </Fragment>);
  }
}
