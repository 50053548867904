import React, { useState, useEffect } from "react";
import AppConfig from "../../AppConfig";
import authService from "../api-authorization/AuthorizeService";
import { toast } from "react-toastify";
// import { Navigate } from 'react-router-dom';
// import auth from '../auth/auth';

export const RegisterComponent = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  // const [isBuyer, setisBuyer]= useState(true);
  const [message, setMessage] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [redirect, setRedirect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {}, []);

  const validatePassword = (e) => {
    var validated = true;
    var errM = "";
    if (e.value.length < 8) {
      validated = false;
      errM += " up to 8 characters";
    }
    if (!/\d/.test(e.value)) {
      validated = false;
      errM += errM === "" ? "at least one digit" : ", at least one digit";
    }
    if (!/[a-z]/.test(e.value)) {
      validated = false;
      errM += errM === "" ? "at least one lower case" : ", one lower case";
    }
    if (!/[A-Z]/.test(e.value)) {
      validated = false;
      errM += errM === "" ? "at least one upper case" : ", one upper case";
    }
    if (/[a-zA-Z0-9]{8,}/.test(e.value)) {
      validated = false;
      errM +=
        errM === "" ? "special character" : ", and have a special character";
    }

    setMessage("");
    e.style.borderColor = "green";
    if (!validated) {
      setMessage("Password must contain " + errM);
      e.style.borderColor = "red";
    }
    return validated;
  };
  const handleSubmit = (btn) => {
    if (cpassword !== password) {
      setMessage("Password does not match.");
      return;
    }

    if (/[a-zA-Z0-9]{8,}/.test(password)) {
      setMessage("Invalid password.");
      return;
    }

    if (username.includes("@")) {
      setMessage("Username cannot contain @.");
      return;
    }

    const userData = JSON.stringify({
      email: email,
      name: username,
      password: password,
    });
    try {
      (async () => {
        setIsLoading(true);
        var result = await AppConfig.PostData(
          `account/register`,
          "POST",
          userData
        );
        // console.log(result);
        if (result["error"] !== null && result["error"] !== undefined) {
          setMessage(result["error"]);
          toast.error(result["error"]);
        } else {
          if (result["token"] !== null && result["token"] !== undefined) {
            toast("Successfull Login");
            setMessage("Successfull Login");
            localStorage.setItem("access_token", result["token"]);
            localStorage.setItem("role", result["role"]);
            localStorage.setItem("email", result["email"]);
            localStorage.setItem("name", result["name"]);
            localStorage.setItem("id", result["id"]);
            sessionStorage.setItem("last_login", JSON.stringify(new Date()));
            authService.signIn();
            const redirectUrl = sessionStorage.getItem("redirectUrl");
            if (result["role"] !== "admin") {
              window.location.href = window.location.origin;
            } else if (redirectUrl != null) {
              if (redirectUrl.endsWith("undefined")) {
                window.location.href = window.location.origin;
              } else {
                window.location.href = redirectUrl;
              }
              sessionStorage.removeItem("redirectUrl");
            } else {
              window.location.href = "/authorize/profile";
            }
            setRedirect(true);
            return;
          } else {
            setMessage(result);
            toast(result);
          }
        }
        setIsLoading(false);
        return;
      })();
    } catch (error) {
      setMessage(error.toString().replace("TypeError: ", ""));
    }

    if (redirect) {
      if (localStorage.getItem("navigating_to") != null) {
        var ref = localStorage.getItem("navigating_to");
        localStorage.removeItem("navigating_to");
        window.location.href = ref;
      } else {
        window.location.href = "/authorize/profile";
      }
      // return <Navigate to='/Update-Profile' replace />
    }
  };

  const togglePWD = (e) => {
    // toggle the type attribute
    const pwd = document.getElementById("Password");
    if (pwd == null) return;
    const newType = passwordType === "password" ? "text" : "password";
    setPasswordType(newType);
    // toggle the eye icon
    e.className = passwordType === "password" ? "fa fa-eye-slash" : "fa fa-eye";
  };
  return (
    <React.Fragment>
      <form name="frm" id="frm" className="col-md-4 col-lg-3">
        {/* <div className='form-group justify-content-center d-flex'>
                    <div className="btn-group" hidden>
                        <label className="rdb-btn btn btn-outline-primary btn-primary text-white"
                            onClick={e=>{
                                setisBuyer(true);
                                var els = document.getElementsByClassName("rdb-btn");
                                for(var i=0; i<els.length;i++){
                                    els[i].className = "rdb-btn btn btn-outline-primary btn-primary text-white";
                                }
                                els = document.getElementsByClassName("rdb-btn2");
                                for(var j=0; j<els.length;j++){
                                    els[j].className = "rdb-btn2 btn btn-outline-primary";
                                }
                            }}
                        >
                            I am a Buyer
                        </label>

                        <label className="rdb-btn2 btn btn-outline-primary"
                            onClick={e=>{
                                setisBuyer(false);
                                var els = document.getElementsByClassName("rdb-btn2");
                                for(var i=0; i<els.length;i++){
                                    els[i].className = "rdb-btn2 btn btn-outline-primary btn-primary text-white";
                                }
                                els = document.getElementsByClassName("rdb-btn");
                                for(var j=0; j<els.length;j++){
                                    els[j].className = "rdb-btn btn btn-outline-primary";
                                }
                            }}>
                            I am a Seller
                        </label>
                    </div>
                </div> */}
        <div className="form-group">
          <label className="control-label lbl">Email</label>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              <i className="fa fa-envelope"></i>
            </span>
            <input
              required
              className="form-control"
              type="email"
              placeholder="Enter Email"
              defaultValue={email}
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              aria-describedby="basic-addon1"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label lbl">Username</label>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              <i className="fa fa-user"></i>
            </span>
            <input
              required
              className="form-control"
              type="email"
              placeholder="Enter Username"
              defaultValue={username}
              autoFocus
              onChange={(e) => setUsername(e.target.value)}
              aria-describedby="basic-addon1"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label lbl">Password</label>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              <i className="fa fa-key"></i>
            </span>
            <input
              required
              className="form-control"
              type={passwordType}
              placeholder="Enter Password"
              defaultValue={password}
              id="Password"
              onChange={(e) => {
                setPassword(e.target.value);
                validatePassword(e.target);
              }}
              aria-describedby="basic-addon1"
              data-toggle="password"
            />
            <span className="input-group-text">
              <i
                className="fa fa-eye-slash"
                onClick={(e) => {
                  togglePWD(e.target);
                }}
              ></i>
            </span>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label lbl">Confirm Password</label>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              <i className="fa fa-key"></i>
            </span>
            <input
              required
              className="form-control"
              type={passwordType}
              placeholder="Re-type Your Password"
              defaultValue={cpassword}
              onChange={(e) => setCPassword(e.target.value)}
              aria-describedby="basic-addon1"
            />
          </div>
        </div>
        <div className="form-group">
          <div className="row text-center p-4">
            <input
              type="checkbox"
              checked
              required
              className="form-control-sm"
            />
            <span className="ml-2"> Agree to our term and condition.</span>
          </div>
        </div>
        <hr className="hr" />
        <div className="form-group text-center">
          {isLoading && (
            <button type="button" className="btn btn-primary btn-5">
              <i className="fa fa-spinner fa-spin"></i> Submiting...
            </button>
          )}
          {!isLoading && (
            <button
              type="submit"
              className="btn btn-primary btn-5"
              onClick={(e) => {
                e.preventDefault();
                return handleSubmit(this);
              }}
            >
              Register
            </button>
          )}
          <div className="text-danger">{message}</div>
          <div>
            <a href="authorize/login">Login instead?</a>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
};
