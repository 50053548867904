import React from "react";

export default function ErrorPage({ErrorMessage}) {
  return (
    <>
      <div className="details-box col-md-6 card shadow p-5 text-center">
        <h2 className=" text-warning">Sorry, an error has occured!</h2>
        <h4>
          <i className="fa fa-exclamation-triangle fa-4x me-2 text-warning"></i>
        </h4>
        <h4>
          <span className=" text-danger">{ErrorMessage}</span>
        </h4>
        
      </div>
    </>
  );
}
