import React, { useState } from "react";
import { toast } from "react-toastify";
import AppConfig from "../AppConfig";
import { LoaderFull } from "./LoaderFull";
import { useEffect } from "react";
import LogoUpload from "./LogoUpload";
import TimezoneSelector from "./TimezoneSelector";
import UserProject from "./Users/UserProject";
import ProjectDataGroup from "./Project/ProjectDataGroup";

export function Dashboard() {
  const [loading, setLoading] = useState(false);
  // const [userId, setUserId] = useState(Number(AppConfig.getAccessUserId()));
  const [users, setUsers] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [region, setRegion] = useState([]);
  const [projects, setProjects] = useState([]);
  const [attendances, setAttendances] = useState([]);

  useEffect(() => {
    populateData();
  }, []);

  const populateData = async () => {
    setLoading(true);
    var result = await AppConfig.PostData("dashboard/all", "GET");
    //  console.log(result)
    if (result["data"] !== undefined) {
      if (
        AppConfig.getAccessRole() === "admin" ||
        AppConfig.getAccessRole() === "staff"
      ) {
        setCompanies(result["data"].companies);
        setUsers(result["data"].users);
        setStaffs(result["data"].staffs);
        setProjects(result["data"].projects);
      }
      setAttendances(result["data"].attendances);
    } else {
      toast(result["error"]);
    }
    setLoading(false);
  };

  const today = new Date(); // Get the current date

  const countAttendancesToday = attendances.filter((x) => {
    // Assuming x.timeIn is a Date object
    const timeInDate = new Date(x.timeIn);

    // Compare the year, month, and day components of timeInDate with today
    return (
      timeInDate.getFullYear() === today.getFullYear() &&
      timeInDate.getMonth() === today.getMonth() &&
      timeInDate.getDate() === today.getDate()
    );
  }).length;

  const isDateInCurrentWeek = (date) => {
    const startOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay()
    );
    const endOfWeek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay() + 6
    );

    return date >= startOfWeek && date <= endOfWeek;
  };

  // Assuming attendances is an array of objects with a 'timeIn' property as a Date object
  const countAttendancesThisWeek = attendances.filter((x) => {
    // Assuming x.timeIn is a Date object
    const timeInDate = new Date(x.timeIn);

    // Check if timeInDate falls within the current week
    return isDateInCurrentWeek(timeInDate);
  }).length;

  const guestData = () => {
    return (
      <div className="row justify-content-center gap-2">
        <div className="dboard-card card-blue hover-shadow">
          <div>
            <i className="fa fa-star fa-2x text-warning"></i>
          </div>
          <h5 className="card-header border-bottom">Today's Attendances</h5>
          <h4>{countAttendancesToday}</h4>
        </div>
      </div>
    );
  };

  const adminData = () => {
    return (
      <>
        <div className="row justify-content-center gap-2">
          <div className="dboard-card card-blue hover-shadow">
            <div>
              <i className="fa fa-users fa-2x text-warning"></i>
            </div>
            <h5 className="card-header border-bottom">Active Users</h5>
            <h4>
              {users.filter((x) => x.status === 1 || x.status === true).length}
            </h4>
          </div>
          <div className="dboard-card card-light-blue hover-shadow">
            <div>
              <i className="fa fa-users fa-2x text-primary"></i>
            </div>
            <h5 className="card-header border-bottom">Inactive Users</h5>
            <h4>
              {users.filter((x) => x.status === 0 || x.status === false).length}
            </h4>
          </div>
          <div className="dboard-card card-blue hover-shadow">
            <div>
              <i className="fas fa-briefcase fa-2x text-warning"></i>
            </div>
            <h5 className="card-header border-bottom">Active Projects</h5>
            <h4>
              {
                projects.filter((x) => x.status === 1 || x.status === true)
                  .length
              }
            </h4>
          </div>
          <div className="dboard-card card-light-blue hover-shadow">
            <div>
              <i className="fas fa-briefcase fa-2x text-primary"></i>
            </div>
            <h5 className="card-header border-bottom">Inactive Projects</h5>
            <h4>
              {
                projects.filter((x) => x.status === 0 || x.status === false)
                  .length
              }
            </h4>
          </div>
          <div hidden className="dboard-card card-blue hover-shadow">
            <div>
              <i className="fas fa-briefcase fa-2x text-warning"></i>
            </div>
            <h5 className="card-header border-bottom">Active Companies</h5>
            <h4>
              {
                companies.filter((x) => x.status === 1 || x.status === true)
                  .length
              }
            </h4>
          </div>
          <div hidden className="dboard-card card-light-blue hover-shadow">
            <div>
              <i className="fas fa-briefcase fa-2x text-primary"></i>
            </div>
            <h5 className="card-header border-bottom">Inactive Companies</h5>
            <h4>
              {
                companies.filter((x) => x.status === 0 || x.status === false)
                  .length
              }
            </h4>
          </div>
          <div hidden className="dboard-card card-blue hover-shadow">
            <div>
              <i className="fa fa-user-circle fa-2x text-warning"></i>
            </div>
            <h5 className="card-header border-bottom">Active Staffs</h5>
            <h4>
              {staffs.filter((x) => x.status === 1 || x.status === true).length}
            </h4>
          </div>
          <div hidden className="dboard-card card-light-blue hover-shadow">
            <div>
              <i className="fa fa-user-circle fa-2x text-primary"></i>
            </div>
            <h5 className="card-header border-bottom">Inactive Staffs</h5>
            <h4>
              {
                staffs.filter((x) => x.status === 0 || x.status === false)
                  .length
              }
            </h4>
          </div>
          <div hidden className="dboard-card card-blue hover-shadow">
            <div>
              <i className="fa fa-star fa-2x text-warning"></i>
            </div>
            <h5 className="card-header border-bottom">Today's Attendances</h5>
            <h4>{countAttendancesToday}</h4>
          </div>
          <div hidden className="dboard-card card-light-blue hover-shadow">
            <div>
              <i className="fa fa-star fa-2x text-primary"></i>
            </div>
            <h5 className="card-header border-bottom">This Week Attendances</h5>
            <h4>{countAttendancesThisWeek}</h4>
          </div>
        </div>
      </>
    );
  };
  if (loading) return <LoaderFull />;

  return (
    <div>
      <div className="row justify-content-center" aria-labelledby="tabelLabel">
        <div className="details-box card shadow p-0">
          <h4 className="card-header projects-header p-2 text-center">
            Dashboard
            <div className="p-2">
              <div className="row justify-content-center gap-4">
                {AppConfig.getAccessRole() !== "guest" && (<div className="dashboard-block">
                  <label>Time/Date: {region}</label>
                </div>)}
                <div className="dashboard-block">
                  <img
                    src={AppConfig.baseAPIUrl + "settings/get_logo"}
                    alt=""
                    className="rounded sitelogo"
                  />
                </div>
                {AppConfig.getAccessRole() !== "guest" && (<div className="dashboard-block">
                  <a href="/authorize/register-admin">
                    <i className="fa fa-key"></i>
                    <span className="link-name">Add User</span>
                  </a>
                </div>)}
              </div>
            </div>
          </h4>
          {AppConfig.getAccessRole() === "guest" && (
            <div className="p-4 text-center text-white">{guestData()}</div>
          )}
          {AppConfig.getAccessRole() === "staff" && (
            <div className="row p-0 m-0">
              <ProjectDataGroup
                users={users}
                projects={projects}
                companies={companies}
                staffs={staffs}
              />
              <div className="border-start col-md-6 col-lg-4 projects-header">
                <div className="p-4 text-center text-white row gap-2 justify-content-center">
                  <UserProject users={users} projects={projects} />
                </div>
              </div>
            </div>
          )}
          {AppConfig.getAccessRole() === "admin" && (
            <div className="row p-0 m-0">
              <ProjectDataGroup
                users={users}
                projects={projects}
                companies={companies}
                staffs={staffs}
              />
              <div className="col-md-6 col-lg-8 text-center text-white bg-dark-green p-0 pb-2 bg-light">
                <h4 className="projects-header border-light border-bottom pt-4 mb-4">
                  Site Info
                </h4>
                {adminData()}
              </div>
              <div className="border-start col-md-6 col-lg-4 projects-header">
                <h4 className="projects-header border-light border-bottom pt-4">
                  Settings
                </h4>
                <div className="p-4 text-center text-white row gap-2 justify-content-center">
                  <LogoUpload />
                  <TimezoneSelector setRegion={setRegion}/>
                  <UserProject users={users} projects={projects} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
