import React from "react";
import { useEffect } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import 'react-notifications/lib/notifications.css';

const CustomAlert = ({msg, type = "info", title = '', timeout = 5000}) => {
  useEffect (() => {
    if(!msg) return;
    switch (type) {
      case 'info':
        NotificationManager.info(msg, title, timeout);
        break;
      case "success":
        NotificationManager.success(msg, title, timeout);
        break;
      case "warning":
        NotificationManager.warning(msg, title, timeout);
        break;
      default:
        NotificationManager.error(msg, title, timeout);
        break;
    };
  }, [msg]);

  return <NotificationContainer  />;
}
export default  CustomAlert;