// file = Html5QrcodePlugin.jsx
import { Html5QrcodeScanner } from "html5-qrcode";
import { useState } from "react";
import { useEffect } from "react";

const qrcodeRegionId = "html5qr-code-full-region";

// Creates the configuration object for Html5QrcodeScanner.
const createConfig = (props) => {
  let config = {};
  if (props.fps) {
    config.fps = props.fps;
  }
  if (props.qrbox) {
    config.qrbox = props.qrbox;
  }
  if (props.aspectRatio) {
    config.aspectRatio = props.aspectRatio;
  }
  if (props.disableFlip !== undefined) {
    config.disableFlip = props.disableFlip;
  }
  return config;
};

const Html5QrcodePlugin = (props) => {
  const [scanError, setScanError] = useState("");
  const [scanResult, setScanResult] = useState("Ready");

  const onNewScanResult = (decodedText, decodedResult) => {
    setScanResult(decodedText)
    props.processImage(decodedText);
  };

  const qrCodeErrorCallback = (err) => {
    setScanError(err)
  };

  useEffect(() => {
    // when component mounts
    const config = createConfig(props);
    const verbose = props.verbose === true;
    // Suceess callback is required.
    // if (!(props.qrCodeSuccessCallback)) {
    //     throw "qrCodeSuccessCallback is required callback.";
    // }
    const html5QrcodeScanner = new Html5QrcodeScanner(
      qrcodeRegionId,
      config,
      verbose
    );
    // html5QrcodeScanner.render(props.qrCodeSuccessCallback, props.qrCodeErrorCallback);
    html5QrcodeScanner.render(onNewScanResult, qrCodeErrorCallback);

    // cleanup function when component will unmount
    return () => {
      html5QrcodeScanner.clear().catch((error) => {
        console.error("Failed to clear html5QrcodeScanner. ", error);
      });
    };
  }, []);

  return (
    <>
      <div id={qrcodeRegionId} />
      <p>{scanResult}</p>
      {/* <p>{scanError}</p> */}
    </>
  );
};

export default Html5QrcodePlugin;
