import React, { Component } from 'react';
import suportLogo from '../../images/technical_support_services.jpg'
import AppConfig from '../../AppConfig';
import { toast } from 'react-toastify';


class SupportComponent extends Component {
    constructor(props) {
        super(props);

        this.msgTitle = '' ;
        this.msg = '' ;
    }
    state = { 
        textarea: {'min-height': '150px'},
        isUpLoading: false, msgS: '', msgF: '',
        msgTitle: this.msgTitle,
        msg: this.msg,
        email: this.email,
    }

    

    Send_Support_Email(){
        (async () => {
            this.setState({msgF: ""})
            this.setState({msgS: ""})
            if(this.state.email==="" || this.state.msg==="" || this.state.msgTitle===""){
                alert("All values are required!");
                return;
            }
            var answer = window.confirm("Send Support Email?");
            if(answer){
                this.setState({isUpLoading: true});
                const body = JSON.stringify({
                    msgTitle: this.state.msgTitle, 
                    msg: this.state.msg,
                    email: this.state.email,
                });
                var result = await AppConfig.PostData(`Send_Support_Email`, 'POST', body);
                // console.log(result);
                if(result["exception"] !== undefined || 
                    result["error"] !== undefined){
                    toast.error(result['error']);
                }else if(result["result"] === "success" || 
                    result["result"] === true || 
                    result["data"] !== undefined){
                }else{
                    toast(result['message']);
                    this.setState({msgF: result['message']})
                }
                this.setState({isUpLoading: false});
            }
        })().then(()=>{this.setState({isUpLoading: false})});
    }

    render() { 
        return ( 
            <div className="col-md-12 text-center justify-content-center" id="Support">
                <div className='p-4'>
                    <div className='card-header'>
                        <h4 className='pt-2'><b>Have a question?</b></h4>
                        <h5>Contact Us</h5>
                        <div className="row" hidden>
                            <div className="col-md-6">
                                <p className='ps-2 text-secondary'>
                                    <h6 className='text-primary'>Head Office Address:</h6>
                                    6815 Riverdale road, Riverdale, An Maryland 20737 USA
                                </p>
                            </div>
                            <div className="col-md-6">
                                <p className='ps-2 text-secondary'>
                                    <h6 className='text-primary'>Branch Office Address:</h6>
                                    No 15 Nnobi road, along Awka-Etiti rd, Nnobi, Anambra State, Nigeria
                                </p>
                            </div>
                            <div className="col-md-6">
                                <p className='text-secondary' href='/'>
                                    <h6 className='text-primary'>Telephone (USA):</h6>                            
                                    <a className='text-secondary' href='tel:443-477-1671'> 443-477-1671</a>
                                </p>
                            </div>
                            <div className="col-md-6">
                                <p className='text-secondary' href='/'>
                                    <h6 className='text-primary'>Telephone (NG):</h6>
                                    <a className='text-secondary' href='tel:+234-705-185-6197'> (+234)-705-185-6197</a>
                                </p>
                            </div>
                        </div>
                        <p>
                            Use the form bellow to lodge your request/complains and our nearest 
                            and available agent will reply you shortly.
                        </p>
                    </div>
                    <div animation-type='fadeInLeft' className='animated-item animated fadeInLeft row justify-content-center d-flex mb-4 mt-4'>
                        <div className='shadow bg-light col-md-6 card p-4 mb-2'>
                            <div className='row'>
                                <div className='form-group text-start col-md-6'>
                                    <label className='control-label lbl'>Email</label>
                                    <input required className='form-control' type='email' name='Email' 
                                        id='Email' placeholder='Enter Your Email Address'
                                        value={this.state.email} 
                                        onChange={e=>{this.setState({email: e.target.value})}}
                                        />
                                </div>
                                <div className='form-group text-start col-md-6'>
                                    <label className='control-label lbl'>Title</label>
                                    <input required className='form-control' type='text' name='Title' 
                                        id='Title' placeholder='Enter Message Title'
                                        defaultValue={this.msgTitle} 
                                        value={this.state.msgTitle} 
                                        onChange={e=>{this.setState({msgTitle: e.target.value})}}
                                    />
                                </div>
                            </div>
                            <div className='form-group text-start'>
                                <label className='control-label lbl'>Message</label>
                                <textarea required className='form-control' style={this.state.textarea} 
                                    name='Message' id='Message' placeholder='Enter Message'
                                        defaultValue={this.msg}
                                        value={this.state.msg} 
                                        onChange={e=>{this.setState({msg: e.target.value})}}
                                >
                                </textarea>
                            </div>
                            <hr className='hr'/>
                            <div className="form-group">
                                <p className='text-success'>{this.state.msgS}</p>
                                <p className='text-danger'>{this.state.msgF}</p>
                            </div>
                            <div className='form-group'>
                                {
                                    !this.state.isUpLoading && <React.Fragment>
                                        <button className='btn btn-primary btn-5' 
                                            onClick={()=>{
                                                this.Send_Support_Email()
                                            }} >
                                            <i className='fa fa-send'></i>
                                            Submit Message
                                        </button>
                                    </React.Fragment>
                                }
                                { 
                                    this.state.isUpLoading && <button disabled
                                        className='btn btn-primary col-md-6'>
                                        <i className='fa fa-spinner fa-spin me-2'></i>
                                        Sending Message...</button>
                                }
                            </div>
                        </div>
                        <div className='col-md-5'>
                            <img src={suportLogo} className='rounded w-100' alt="suportLogo"/>
                        </div>
                    </div>
                </div>
            </div>
         );
    }
}
 
export default SupportComponent;