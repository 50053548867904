import React, { Component } from 'react';
import suportLogo from '../../images/technical_support_services.jpg';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import AppConfig from '../../AppConfig';
import { toast } from 'react-toastify';


export default class SupportAdminComponent extends Component {
    constructor(props) {
        super(props);

        console.log(props.email_id)
        this.email_id = '' ;
        this.msgTitle = '' ;
        this.msg = '' ;
        this.handleMessageChange = this.handleMessageChange.bind(this)
        if(props.email_id != null && props.email_id !== ''){
            this.email_id = props.email_id;
            // this.msgTitle = 'Enquiry into Property - ' + props.property_id;
            // this.msg = 'Hi Anakings, am [your name here] \n' +
            //     'Am interested in the property with the id: ' + 
            //     props.property_id + '\n\n' + 
            //     "[Compose your message here]\n\n\n" +
            //     "Property Link:\n" +
            //     auth.siteUrl() + "/Search-Properties/" + props.property_id;
        }
    }
    state = { 
        textarea: {'min-height': '150px'},
        isUpLoading: false, msgS: '', msgF: '',
        msgTitle: this.msgTitle,
        msg: this.msg,
        email: this.props.email_id
    }


    componentDidMount() {
    }
    
    handleMessageChange = (input) => e =>{
        if(input==="msg"){
            // console.log(e)
            this.setState({[input]: e});
        }else
            this.setState({[input]: e.target.value});
    }


    Send_Support_Email(){
        if(AppConfig.getAccessRole() !== 'admin') return;
        (async () => {
            this.setState({msgF: ""})
            this.setState({msgS: ""})
            if(this.state.email==="" || this.state.msg==="" || this.state.msgTitle===""){
                alert("All values are required!");
                return;
            }
            var answer = window.confirm("Send Support Email to " + this.state.email + "?");
            if(answer){
                this.setState({isUpLoading: true});
                const body = JSON.stringify({
                    msgTitle: this.state.msgTitle, 
                    msg: this.state.msg,
                    email: this.state.email
                });
                var result = await AppConfig.PostData(`Send_Admin_Support_Email`, 'POST', body);
                // console.log(result);
                if(result["exception"] !== undefined || 
                    result["error"] !== undefined){
                    toast.error(result['error']);
                }else if(result["result"] === "success" || 
                    result["result"] === true || 
                    result["data"] !== undefined){
                }else{
                    toast(result['message']);
                    this.setState({msgF: result['message']})
                }
                this.setState({isUpLoading: false});
            }
        })().then(()=>{this.setState({isUpLoading: false})});
    }

    render() { 
        return ( 
            <div className="col-md-12 text-center bg-light justify-content-center">
                <div className='bg-primary-new p-4'>
                    <div className='card-header'>
                        <h4>Send Support Email</h4>
                    </div>
                    <p>
                        Use the form bellow to send professional company support emails to users.
                    </p>
                    <p>To send to multiple users at once, use comma to seperate the emails addresses.</p>
                    <div animation-type='fadeInLeft' className='animated-item animated fadeInLeft row justify-content-center d-flex mb-4 mt-4'>
                        <div className='shadow bg-light col-md-6 card p-4 mb-2'>
                            <div className='row'>
                                <div className='form-group text-start col-md-6'>
                                    <label className='control-label lbl'>Email</label>
                                    <input required className='form-control' type='email' name='Email' 
                                        id='Email' placeholder='Enter Your Email Address'
                                        defaultValue={this.state.email} 
                                        onChange={e=>{this.setState({email: e.target.value})}}
                                        />
                                </div>
                                <div className='form-group text-start col-md-6'>
                                    <label className='control-label lbl'>Title</label>
                                    <input required className='form-control' type='text' name='Title' 
                                        id='Title' placeholder='Enter Message Title'
                                        defaultValue={this.msgTitle}
                                        onChange={e=>{this.setState({msgTitle: e.target.value})}}
                                    />
                                </div>
                            </div>
                            <div className='form-group text-start'>
                                <label className='control-label lbl'>Message</label>
                                {/* <!-- Create the editor container --> */}
                                <ReactQuill required className='textarea' 
                                    placeholder='Enter Message'
                                    defaultValue={this.state.msg}
                                    onChange={this.handleMessageChange("msg")} >
                                </ReactQuill>
                            </div>
                            <hr className='hr'/>
                            <div className="form-group">
                                <p className='text-success'>{this.state.msgS}</p>
                                <p className='text-danger'>{this.state.msgF}</p>
                            </div>
                            <div className='form-group'>
                                {
                                    !this.state.isUpLoading && <React.Fragment>
                                        <button className='btn btn-primary btn-5' 
                                            onClick={()=>{
                                                this.Send_Support_Email()
                                            }} >
                                            <i className='fa fa-send me-2'></i>
                                            Submit Message
                                        </button>
                                    </React.Fragment>
                                }
                                { 
                                    this.state.isUpLoading && <button disabled
                                        className='btn btn-primary col-md-6'>
                                        <i className='fa fa-spinner fa-spin me-2'></i>
                                        Sending Message...</button>
                                }
                            </div>
                        </div>
                        <div className='col-md-5'>
                            <img src={suportLogo} className='rounded w-100' alt="suportLogo"/>
                        </div>
                    </div>
                </div>
            </div>
         );
    }
}
