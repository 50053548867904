import React, { useState, useEffect } from "react";
import AppConfig from "../../AppConfig";
import { toast } from "react-toastify";

export const RegisterAdminComponent = () => {
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [role, setRole] = useState(AppConfig.roles.staff);
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  // const [isBuyer, setisBuyer]= useState(true);
  const [message, setMessage] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {}, []);

  const validatePassword = (e) => {
    var validated = true;
    var errM = "";
    if (e.value.length < 8) {
      validated = false;
      errM += " up to 8 characters";
    }
    if (!/\d/.test(e.value)) {
      validated = false;
      errM += errM === "" ? "at least one digit" : ", at least one digit";
    }
    if (!/[a-z]/.test(e.value)) {
      validated = false;
      errM += errM === "" ? "at least one lower case" : ", one lower case";
    }
    if (!/[A-Z]/.test(e.value)) {
      validated = false;
      errM += errM === "" ? "at least one upper case" : ", one upper case";
    }
    if (/[a-zA-Z0-9]{8,}/.test(e.value)) {
      validated = false;
      errM +=
        errM === "" ? "special character" : ", and have a special character";
    }

    setMessage("");
    e.style.borderColor = "green";
    if (!validated) {
      setMessage("Password must contain " + errM);
      e.style.borderColor = "red";
    }
    return validated;
  };

  const handleSubmit = (form) => {
    if (cpassword !== password) {
      setMessage("Password does not match.");
      return;
    }

    if (/[a-zA-Z0-9]{8,}/.test(password)) {
      setMessage("Invalid password.");
      return;
    }

    if (username.includes("@")) {
      setMessage("Username cannot contain @.");
      return;
    }

    let userData = JSON.stringify({
      email: email,
      name: username,
      password: password,
      role: role,
    });
    if (AppConfig.getAccessRole() === "staff")
      userData = JSON.stringify({
        email: email,
        name: username,
        password: password,
      });
    try {
      (async () => {
        setIsLoading(true);
        var result = await AppConfig.PostData(
          `account/register`,
          "POST",
          userData
        );
        // console.log(result);
        if (result["error"] !== null && result["error"] !== undefined) {
          setMessage(result["error"]);
          toast.error(result["error"]);
        } else {
          if (result["message"] !== null && result["message"] !== undefined) {
            toast(result["message"]);
          } else {
            setMessage(result);
            toast(result);
          }
        }
        setIsLoading(false);
        return;
      })();
    } catch (error) {
      setMessage(error.toString().replace("TypeError: ", ""));
    }
  };

  const togglePWD = (e) => {
    // toggle the type attribute
    const pwd = document.getElementById("Password");
    if (pwd == null) return;
    const newType = passwordType === "password" ? "text" : "password";
    setPasswordType(newType);
    // toggle the eye icon
    e.className = passwordType === "password" ? "fa fa-eye-slash" : "fa fa-eye";
  };
  return (
    <React.Fragment>
      <form
        name="frm"
        id="frm"
        className="col-md-4 col-lg-3"
        onSubmit={(e) => {
          e.preventDefault();
          return handleSubmit(this);
        }}
      >
        <div className="form-group">
          <label className="control-label lbl">Email</label>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              <i className="fa fa-envelope"></i>
            </span>
            <input
              required
              className="form-control"
              type="email"
              placeholder="Enter Email"
              defaultValue={email}
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
              aria-describedby="basic-addon1"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label lbl">Username</label>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              <i className="fa fa-user"></i>
            </span>
            <input
              required
              className="form-control"
              type="username"
              placeholder="Enter Username"
              defaultValue={username}
              autoFocus
              onChange={(e) => setUsername(e.target.value)}
              aria-describedby="basic-addon1"
            />
          </div>
        </div>
        <div className="form-group">
          <label className="control-label lbl">Password</label>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              <i className="fa fa-key"></i>
            </span>
            <input
              required
              className="form-control"
              type={passwordType}
              placeholder="Enter Password"
              defaultValue={password}
              id="Password"
              onChange={(e) => {
                setPassword(e.target.value);
                validatePassword(e.target);
              }}
              aria-describedby="basic-addon1"
              data-toggle="password"
            />
            <span className="input-group-text">
              <i
                className="fa fa-eye-slash"
                onClick={(e) => {
                  togglePWD(e.target);
                }}
              ></i>
            </span>
          </div>
        </div>
        <div className="form-group">
          <label className="control-label lbl">Confirm Password</label>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              <i className="fa fa-key"></i>
            </span>
            <input
              required
              className="form-control"
              type={passwordType}
              placeholder="Re-type Your Password"
              defaultValue={cpassword}
              onChange={(e) => setCPassword(e.target.value)}
              aria-describedby="basic-addon1"
            />
          </div>
        </div>
        {AppConfig.getAccessRole() === "admin" && (
          <div className="form-group">
            <label className="control-label lbl">User Type</label>
            <select
              className="form-select form-control"
              onChange={(e) => setRole(e.target.value)}
            >
              <option value={"staff"}>Admin</option>
              <option value={"guest"}>User</option>
            </select>
          </div>
        )}
        <hr className="hr" />
        <div className="form-group text-center">
          {isLoading && (
            <button type="button" className="btn btn-primary btn-5">
              <i className="fa fa-spinner fa-spin"></i> Submiting...
            </button>
          )}
          {!isLoading && (
            <button type="submit" className="btn btn-primary btn-5">
              Register
            </button>
          )}
          <div className="text-warning">{message}</div>
        </div>
      </form>
    </React.Fragment>
  );
};
