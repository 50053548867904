import React, { useRef } from "react";

export function CountPerPageDropDown({ handlePerPageChange, perPage }) {
  const myRef = useRef(null);

  const toggleDropDown = (ref) => {
    ref.current.classList.toggle("dropdown-menu");
  };

  return (
    <div className="dropdown">
      <button
        className="btn btn-secondary dropdown-toggle "
        type="button"
        id="perPageDropdown"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={(e) => {
          e.preventDefault();
          toggleDropDown(myRef);
        }}
      >
        Show {perPage}
      </button>
      <ul className="dropdown-menu p-0" ref={myRef} aria-labelledby="perPageDropdown">
        {[25, 50, 100].map((num) => (
          <li>
            <button
              className="dropdown-item"
              onClick={() => {
                handlePerPageChange(num)
                toggleDropDown(myRef);}
              }
            >
              {num}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
}
