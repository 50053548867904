import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import AppConfig from "../../AppConfig";

const UserProject = ({ users, projects }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [upLoading, setUpLoading] = useState(false);
  const username = AppConfig.getAccessUser();

  // Fetch projects for the selected user
  useEffect(() => {
    if (selectedUser) {
      // Simulate fetching projects for the selected user (replace with actual fetch call)
      const userProjectsIds = selectedUser.projects.split(",").map(Number);
      setSelectedProjects(
        projects.filter((project) => userProjectsIds.includes(project.id))
      );
    }
  }, [projects, selectedUser]);

  const handleSubmit = async () => {
    setUpLoading(true);
    const selectedIdString = selectedProjects
      .map((project) => project.id)
      .join(",");
    var url = "dashboard/userprojects";

    var result = await AppConfig.PostData(
      url,
      "POST",
      JSON.stringify({
        user: selectedUser.id,
        projects: selectedIdString,
      })
    );
    if (result["error"] !== undefined) {
      toast(result["error"]);
    } else {
      if (result["message"] !== undefined) {
        toast(result["message"]);
      }
    }
    setUpLoading(false);
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
  };

  const handleProjectSelect = (project) => {
    const isProjectSelected = selectedProjects.some((p) => p.id === project.id);
    if (isProjectSelected) {
      setSelectedProjects(selectedProjects.filter((p) => p.id !== project.id));
    } else {
      setSelectedProjects([...selectedProjects, project]);
    }
  };

  return (
    <div className="card p-2 text-secondary">
      <h3 className="car-header">Asign Projects</h3>
      <select
        className="form-select form-control"
        onChange={(e) => handleUserSelect(JSON.parse(e.target.value))}
      >
        <option value="">Select a user</option>
        {users
          .filter((user) => user.role !== "admin" && user.username !== username)
          .map((user) => (
            <option key={user.id} value={JSON.stringify(user)}>
              {user.name}
            </option>
          ))}
      </select>

      {selectedUser && (
        <div className="text-start mt-2 border-top">
          <h6 className="">Select Projects</h6>
          {projects.map((project) => (
            <div key={project.id} className="form-check d-flex gap-2">
              <input
                type="checkbox"
                className="form-check-input"
                id={project.id}
                value={project.id}
                checked={selectedProjects.some((p) => p.id === project.id)}
                onChange={() => handleProjectSelect(project)}
              />
              <label className="form-check-label" htmlFor={project.id}>
                {project.name}
              </label>
            </div>
          ))}
        </div>
      )}
      <div className="border-top">
        {!upLoading && selectedUser && (
          <button
            type="submit"
            className="btn btn-primary mt-2"
            onClick={handleSubmit}
          >
            Save changes
          </button>
        )}
        {upLoading && selectedUser && (
          <button className="btn btn-primary">
            <i className="fa fa-spin fa-spinner"></i>
          </button>
        )}
      </div>
    </div>
  );
};

export default UserProject;
