import React, { Component } from 'react';

export class LayoutBox extends Component {

  render () {
    return (
      <div className='m-4'>
        <div className='row justify-content-center'>
          <div className='details-box col-md-6 card shadow p-0'>
            <div className='card-header h4 p-4'>
              {this.props.title}
            </div>
            <div className='p-4'>
              <p>{this.props.subTitle}</p>
              <hr />
              {this.props.children}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
