import React from "react";
import { Loader } from "./Loader";

export function LoaderFull({ textcolor = "text-white", message }) {

  return (
    <div class="fullscreen-container">
      <div class="content">
        <Loader textcolor = {textcolor} message={message} />
      </div>
    </div>
  );
}
