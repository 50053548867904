import React, { useState } from 'react';
import AppConfig from '../AppConfig';
import { toast } from 'react-toastify';
// import axios from 'axios';

const LogoUpload = () => {
  const [file, setFile] = useState(null);
  const [upLoading, setUpLoading] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append('logo', file);

      try {
        var url = "settings/upload_logo";
    
        var fd = new FormData();
        // fd.append("id", this.state._Id);
        if (file !== null)
          fd.append("logo", file);
        else{
            toast('File is required!'); return;
        }
        setUpLoading(true);
        var resultFile = await AppConfig.PostData(url, "POST", fd, true);
        if (resultFile["error"] !== undefined) {
          toast(resultFile["error"]);
        } else {
          if (resultFile["message"] !== undefined) {
            toast(resultFile["message"]);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        }
        setUpLoading(false);
      } catch (error) {
        console.error('Error uploading file:', error);
        // Handle error
      }
    } else {
      toast('Please select a file.');
    }
  };

  return (
    <div className='card p-2 text-secondary'>
        <h3>Change Logo</h3>
        <input type="file" onChange={handleFileChange} className='form-control'/>
        {!upLoading && <button onClick={handleFileUpload} className='btn btn-primary mt-2'>Upload</button>}
        {upLoading && <button className='btn btn-primary'><i className='fa fa-spin fa-spinner'></i></button>}
    </div>
  );
};

export default LogoUpload;
