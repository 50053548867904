import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import AuthorizeRoute from "./components/api-authorization/AuthorizeRoute";
import { Layout } from "./components/Layout";
// import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "./custom.css";

import "./desktop.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import AppConfig from "./AppConfig";

export default class App extends Component {
  static displayName = App.name;

  render() {
    const logoUrl = `${AppConfig.baseAPIUrl}settings/get_logo`;
    return (
      <Layout>
        <HelmetProvider>
          <Helmet>
            <link rel="icon" href={logoUrl} />
          </Helmet>
        </HelmetProvider>
        <Routes>
          {AppRoutes.map((route, index) => {
            const { element, requireAuth, ...rest } = route;
            return (
              <Route
                key={index}
                {...rest}
                element={
                  requireAuth ? (
                    <AuthorizeRoute {...rest} element={element} />
                  ) : (
                    element
                  )
                }
              />
            );
          })}
        </Routes>
      </Layout>
    );
  }
}
