import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import AppConfig from "../../AppConfig";
import { Loader } from "../Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export class Companies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objs: [],
      projects: [],
      loading: true,
      pageNumber: 0,
      countPerPage: 15,
      sort_by: "name",
      sort_by_order: 1,
      SearchValue: "",
      user_type: -1,
      status: -1,
      step: 0,
      project_Id: 0,
      _id: this.props._id,
      loadingMain: false,
      userId: "",
      msg: "",
    };
  }

  componentDidMount() {
    this.populateProjectsData();
  }

  async populateData(project_Id) {
    AppConfig.setProjectId(project_Id);
    this.setState({ loadingMain: true });
    var url =
      `companies?` +
      new URLSearchParams({
        search_string: this.state.SearchValue,
        status: this.state.status,
      });

    var result = await AppConfig.PostData(url, "GET");
    //  console.log(result)
    if (result["error"] !== undefined) {
      toast(result["status"]);
    } else {
      if (result["data"].length === 0) {
        toast.warn("No result found");
      }
      const newObjs = [];
      result["data"].forEach((prop) => {
        var newItem = {...prop};
        newItem.StatusClass = this.getStatusClass(prop);
        newItem.msg = "";
        newObjs.push(newItem);
      });

      const sortParam = AppConfig.dynamicSort(this.state.sort_by, this.state.sort_by_order);
      newObjs.sort(sortParam);
      this.setState({
        objs: newObjs,
      });
    }
    this.setState({
      loadingMain: false,
    });
  }

  async populateProjectsData() {
    this.setState({ loadingMain: true });
    var url = `projects`

    var result = await AppConfig.PostData(url, "GET");
    //  console.log(result)
    if (result["error"] !== undefined) {
      toast(result["status"]);
    } else {
      this.setState({
        projects: result["data"],
      });
      if (result["data"].length === 0) {
        toast.warn("No projects was found");
      }else{
        this.setState({ project_Id: result['data'][0].id});
        AppConfig.setProjectId(result['data'][0].id);
        this.populateData(result['data'][0].id);
      }
    }
    this.setState({
      loadingMain: false,
    });
  }

  pagesVisited = () => {
    return this.state.pageNumber * this.state.countPerPage;
  };
  pageCount = () => {
    return Math.ceil(this.state.objs.length / this.state.countPerPage);
  };

  changePage = ({ selected }) => {
    this.setState({ pageNumber: selected });
  };

  displayItems = () => {
    return this.state.objs
      .slice(this.pagesVisited(), this.pagesVisited() + this.state.countPerPage)
      .map((item) => {
        return (
          <div className="listitem">
            <h6 className="card-header itemtitle">
              <div className="w-100 d-flex justify-content-end gap-2">
                <a
                  href="#Status"
                  type="button"
                  title={(item.status === 1 || item.status === true) ? `Disable` : `Activate`}
                  onClick={async (e) => {
                    e.preventDefault();
                    item.loading = true;
                    await this.changeStatus(item.id);
                    item.loading = false;
                  }}
                  className={`${
                    (item.status === 1 || item.status === true) ? `btn-success` : `btn-warning`
                  } btn-sm float-end`}
                >
                  <span
                    className={
                      (item.status === 1 || item.status === true) ? `fa fa-unlock` : `fa fa-lock`
                    }
                    // className={item.status === 1 ? `fa fa-check-circle` : `fa fa-lock`}
                  ></span>
                  {item.loading === true && (
                    <i className="fa fa-spinner fa-spin"></i>
                  )}
                </a>
                <a
                  href={"authorize/company/edit/" + item.id}
                  className="btn-sm btn-primary float-end"
                  title="Edit"
                >
                  <span className="fa fa-edit"></span>
                </a>
                <a
                  href="#Delete"
                  title="Delete"
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    this.deleteItem(item.id, item.name);
                  }}
                  className="btn-sm btn-danger float-end"
                >
                  <span className="fa fa-trash"></span>
                </a>
              </div>
              <div>
                <a
                  href={`authorize/company/${item.id}`}
                  className="text-info"
                  target="_blank"
                  rel="noreferrer"
                >
                  {AppConfig.Capitalize(item.name)}
                </a>
              </div>
            </h6>
            <div className="lst shadow p-2">
              <div className="row">
                <div className="form-group col-md-3">
                  <label className="control-label me-2">Last Update:</label>
                  <div className="description">
                    <input
                      type="date"
                      disabled
                      className="form-control-sm"
                      defaultValue={AppConfig.dateConverter(item.updatedAt)}
                      // onChange={() => {this.han(item.id)}}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
  };

  async deleteItem(_id, _title) {
    if (AppConfig.getAccessRole() !== "admin") return;
    var answer = window.confirm("Delete " + _title + "?");
    if (answer) {
      this.setState({ loading: true });
      var result = await AppConfig.PostData(`companies/` + _id, "DELETE");
      if (result["exception"] !== undefined || result["error"] !== undefined) {
        toast.error(result["error"]);
      } else if (result["result"] !== "success" && result["result"] !== true) {
        toast(result["message"]);
      } else {
        toast(result["message"]);
        setTimeout(() => {
          var items = this.state.objs.filter((x) => {
            return x.id !== _id;
          });
          this.setState({ objs: items });
        }, 1000);
      }
      this.setState({
        loading: false,
      });
    }
  }

  async changeStatus(_id) {
    if (AppConfig.getAccessRole() !== "admin") return;
    this.setState({ loading: true });
    var result = await AppConfig.PostData(
      `companies/change_status/` + _id,
      "POST"
    );
    if (result["exception"] !== undefined || result["error"] !== undefined) {
      toast.error(result["error"]);
    } else if (result["result"] !== "success" && result["result"] !== true) {
      toast(result["message"]);
    } else {
      toast(result["message"]);

      const objs = this.state.objs;
      objs.forEach((prop) => {
        if (prop.id === _id) {
          prop.status = (prop.status === 0 || prop.status === false) ? 1 : 0; //true : false; //
        }
      });
      this.setState({ objs });
    }
    this.setState({
      loading: false,
    });
  }

  clearMsg(_id) {
    const { objs } = this.state;
    objs.forEach((prop) => {
      if (prop.id === _id) {
        prop.msg = "";
        return;
      }
    });
    this.setState({ objs });
  }

  getStatusClass = (prop) => {
    var className = "form-select";
    if (prop.isPendingChange) className = "form-select border-primary";
    if (prop.isUpLoading) className = "form-select border-warning";
    if (prop.isChangeSuccessfull) className = "form-select border-success";
    return className;
  };

  setSearchValue(val) {
    this.setState({ SearchValue: val });
  }

  setSortBy(val) {
    this.setState({ sort_by: val });
    const { objs } = this.state;
    objs.sort(AppConfig.dynamicSort(val, this.state.sort_by_order));
  }

  setSortByOrder(val) {
    this.setState({ sort_by_order: val });
    const { objs } = this.state;
    objs.sort(AppConfig.dynamicSort(this.state.sort_by, val));
  }

  render() {
    // let contents = this.state.loadingMain
    //   ? <Loader />
    //   : Branches.renderBranchesTable(this.state.Branches);

    return (
      <div className="">
        <div className="details-box shadow p-2">
          <a href="authorize/company" className="float-end">
            Add New Company
          </a>
          <div className="card-body">
            <div className="card-header p-2 mt-2">
              <h5 className="text-warning">Available Companies</h5>
              <p>Below are the list of available companies.</p>
            </div>
            <div className="text-start mt-2">
              <h6 className="control-label">Filter:</h6>
              <div className="row">
                <div className="form-group maxw-fit-content">
                  <label className="control-label">Project:</label>
                  <select
                    className="form-select"
                    onChange={(e) => {
                      this.setState({ project_Id: e.target.value})
                      this.populateData(e.target.value)
                    }}
                  >
                    {this.state.projects.map(p => <option value={p.id}>{p.name}</option>)}
                  </select>
                </div>
                <div className="form-group maxw-fit-content">
                  <label className="control-label">Status:</label>
                  <select
                    className="form-select"
                    onChange={(e) => {
                      this.setState({ status: e.target.value });
                    }}
                    defaultValue={this.state.status}
                  >
                    <option value="-1" selected>
                      All
                    </option>
                    <option value="1">Active</option>
                    <option value="0">Non Active</option>
                  </select>
                </div>
              </div>
              <div className="row justify-content-between">
                <div className="form-group maxw-fit-content float-start mb-2">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by keywords"
                      defaultValue={this.state.SearchValue}
                      onChange={(e) => {
                        this.setSearchValue(e.target.value);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          // Cancel the default action, if needed
                          e.preventDefault();
                          // Trigger the button element with a click
                          this.populateData(this.state.project_Id);
                        }
                      }}
                    />
                    <span
                      className="input-group-text btn btn-primary"
                      onClick={(e) => {
                        this.populateData(this.state.project_Id);
                      }}
                    >
                      <i className=" fa fa-search"></i>
                    </span>
                  </div>
                </div>
                <div className=" maxw-fit-content text-end pe-4">
                  <label className="control-label mw-150 pe-2">Sort By:</label>
                  <select
                    className="mw-150"
                    onChange={(e) => this.setSortBy(e.target.value)}
                  >
                    <option value="name" selected>
                      Name
                    </option>
                    <option value="id">Id</option>
                    <option value="updatedAt">Updated At</option>
                  </select>
                  <select
                    className="mw-150"
                    onChange={(e) => this.setSortByOrder(e.target.value)}
                  >
                    <option value="1" selected>
                      Asc
                    </option>
                    <option value="-1">Desc</option>
                  </select>
                </div>
              </div>
              <hr />
            </div>
            <div className="navigation">
              {this.state.loadingMain && <Loader />}
              {this.displayItems()}
              <hr />
              <ReactPaginate
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={this.pageCount()}
                onPageChange={this.changePage}
                previousLinkClassName={"btn btn-sm btn-primary"}
                nextLinkClassName={"btn btn-sm btn-primary"}
                activeClassName={"btn btn-sm btn-primary"}
                disabledClassName={"btn btn-sm disabled"}
              />
            </div>
          </div>
          <div className="text-start">
            <a href="authorize/company" className="">
              Add New Company
            </a>
          </div>
        </div>
      </div>
    );
  }
}
