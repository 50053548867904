import { configureStore } from '@reduxjs/toolkit';
import userReducer from './users/userSlice';
import staffReducer from './staffs/staffSlice';
import attendanceReducer from './attendances/attendanceSlice';
import companyReducer from './companies/companySlice';

const store = configureStore({
  reducer: {
    company: companyReducer,
    user: userReducer,
    staff: staffReducer,
    attendance: attendanceReducer,
  },
});

export default store;
