import React, { Component } from "react";
import ReactPaginate from "react-paginate";
import AppConfig from "../../AppConfig";
import { Loader } from "../Loader";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ExportToExcelOrPDF from "../ExportToExcelOrPDF";
import CompanyAttendanceCards from "./CompanyAttendanceCards";
import { CountPerPageDropDown } from "../CountPerPageDropDown";
// import axios from "axios";

export class Attendances extends Component {
  constructor(props) {
    super(props);
    this.state = {
      objs: [],
      companies: [],
      projects: [], project_Id: 0,
      objsToExport: [],
      loading: true,
      pageNumber: 0,
      countPerPage: 25,
      sort_by: "staff.name",
      sort_by_order: 1,
      SearchValue: "",
      user_type: -1,
      status: -1,
      step: 0,
      _id: this.props._id,
      loadingMain: false,
      userId: "",
      msg: "",
      fromDate: new Date(),
      toDate: new Date(),
    };
  }

  componentDidMount() {
    const today = new Date();
    const fromDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - today.getDay()
    );
    this.setState({ fromDate: fromDate });
    // this.populateData();
    // this.populateAllCompaniesData();
    this.populateProjectsData();
  }

  async populateData(project_Id) {
    AppConfig.setProjectId(project_Id);
    this.setState({ loadingMain: true });
    var url =
      // `attendances`
      `attendances?` +
      new URLSearchParams({
        search_string: this.state.SearchValue,
        status: this.state.status,
        fromDate: AppConfig.formatDate(this.state.fromDate),
        toDate: AppConfig.formatDate(this.state.toDate),
      });
      
    var result = await AppConfig.PostData(url, "GET");
    //  console.log('result:')
    //  console.log(result)
    if (result["error"] !== undefined) {
      toast(result["status"]);
    } else {
      if (result["data"].length === 0) {
        // toast.warn("No result found");
      }
      const newObjs = [];
      const newObjsToExport = [];
      // console.log(project_Id);
      result["data"].forEach((prop) => {
        var newItem = { ...prop };
        if(prop.staff?.project_Id === Number(project_Id)){
          newObjs.push(newItem);
          newObjsToExport.push({
            Id: prop.staff_Id,
            Name: prop.staff?.name,
            Company: prop.staff?.company?.name,
            Date: prop.createdOnSimple,
            "Time In": prop.timeIn.split(" ")[1],
            "Time Out": prop.timeOut?.split(" ")[1],
            "Scan In": prop.timeIn,
          });
        }
      });

      const sortParam = AppConfig.dynamicSort(this.state.sort_by, this.state.sort_by_order);
      newObjs.sort(sortParam);
      // newObjsToExport.sort(sortParam);
      this.setState({
        objsToExport: newObjsToExport,
      });

      this.setState({
        objs: newObjs,
      });
    }
    this.setState({
      loadingMain: false,
    });
  }

  async populateAllCompaniesData(project_Id) {
    AppConfig.setProjectId(project_Id);
    var url =
      `companies?` +
      new URLSearchParams({
        status: true,
      });

    var result = await AppConfig.PostData(url, "GET");
    //  console.log(result)
    if (result["error"] !== undefined) {
      // toast(result["status"]);
    } else {
      this.setState({
        companies: result["data"],
      });
    }
  }

  async populateProjectsData() {
    this.setState({ loadingMain: true });
    var url = `projects`

    var result = await AppConfig.PostData(url, "GET");
    //  console.log(result)
    if (result["error"] !== undefined) {
      toast(result["status"]);
    } else {
      this.setState({
        projects: result["data"],
      });
      if (result["data"].length === 0) {
        toast.warn("No projects was found");
      }else{
        this.setState({ project_Id: result['data'][0].id});
        AppConfig.setProjectId(result['data'][0].id);
        this.populateAllCompaniesData(result['data'][0].id);
        this.populateData(result['data'][0].id);
      }
    }
    this.setState({
      loadingMain: false,
    });
  }

  pagesVisited = () => {
    return this.state.pageNumber * this.state.countPerPage;
  };
  pageCount = () => {
    return Math.ceil(this.state.objs.length / this.state.countPerPage);
  };

  changePage = ({ selected }) => {
    this.setState({ pageNumber: selected });
  };

  async deleteItem(_id, _title) {
    if (AppConfig.getAccessRole() !== "admin") return;
    var answer = window.confirm("Delete attendance for " + _title + "?");
    if (answer) {
      this.setState({ loading: true });
      var result = await AppConfig.PostData(`attendances/` + _id, "DELETE");
      if (result["exception"] !== undefined || result["error"] !== undefined) {
        toast.error(result["error"]);
      } else if (result["result"] !== "success" && result["result"] !== true) {
        toast(result["message"]);
      } else {
        toast(result["message"]);
        setTimeout(() => {
          var items = this.state.objs.filter((x) => {
            return x.id !== _id;
          });
          var items2 = this.state.objsToExport.filter((x) => {
            return x.id !== _id;
          });
          this.setState({ objs: items });
          this.setState({ objsToExport: items2 });
        }, 1000);
      }
      this.setState({
        loading: false,
      });
    }
  }

  async changeStatus(_id) {
    if (AppConfig.getAccessRole() !== "admin") return;
    this.setState({ loading: true });
    var result = await AppConfig.PostData(
      `Attendances/change_status?_id=` + _id,
      "POST"
    );
    if (result["exception"] !== undefined || result["error"] !== undefined) {
      toast.error(result["error"]);
    } else if (result["result"] !== "success" && result["result"] !== true) {
      toast(result["message"]);
    } else {
      toast(result["message"]);

      const objs = this.state.objs;
      objs.forEach((prop) => {
        if (prop.id === _id) {
          prop.status = prop.status === 0 ? 1 : 0; //true : false;
        }
      });
      this.setState({ objs });
    }
    this.setState({
      loading: false,
    });
  }

  clearMsg(_id) {
    const { objs } = this.state;
    objs.forEach((prop) => {
      if (prop.id === _id) {
        prop.msg = "";
        return;
      }
    });
    this.setState({ objs });
  }

  getStatusClass = (prop) => {
    var className = "form-select";
    if (prop.isPendingChange) className = "form-select border-primary";
    if (prop.isUpLoading) className = "form-select border-warning";
    if (prop.isChangeSuccessfull) className = "form-select border-success";
    return className;
  };

  setSearchValue(val) {
    this.setState({ SearchValue: val });
  }

  setSortBy(val) {
    this.setState({ sort_by: val });
    const { objs } = this.state;
    objs.sort(AppConfig.dynamicSort(val, this.state.sort_by_order));
  }

  setSortByOrder(val) {
    this.setState({ sort_by_order: val });
    const { objs } = this.state;
    objs.sort(AppConfig.dynamicSort(this.state.sort_by, val));
  }

  displayItems = () => {
    return (
      <>
        <h5 className="text-info border-top pt-4">Available Attendances</h5>
        {this.state.objs.length === 0 && (
          <p className="text-warning">No result found</p>
        )}
        <div class="table-responsive">
          <table className="table-hover table col-md-12 table-light mt-4">
            <thead>
              <tr className="text-secondary">
                <th>ID Number</th>
                <th>Name</th>
                <th>Company</th>
                <th>Date</th>
                <th>Time In</th>
                <th>Time Out</th>
                <th>Scan In</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.objs
                .slice(
                  this.pagesVisited(),
                  this.pagesVisited() + this.state.countPerPage
                )
                .map((item) => {
                  return (
                    <tr>
                      <td>{item.staff_Id}</td>
                      <td>{AppConfig.Capitalize(item.staff?.name)}</td>
                      <td>{AppConfig.Capitalize(item.staff?.company?.name)}</td>
                      <td>{item.createdOnSimple}</td>
                      <td>{item.timeIn.split(" ")[1]}</td>
                      <td>{item.timeOut?.split(" ")[1]}</td>
                      <td>{item.timeIn}</td>
                      <td>
                        <a
                          href="#Delete"
                          title="Delete"
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            this.deleteItem(item.id, item.staff?.name);
                          }}
                          className="btn-sm btn-danger"
                        >
                          <span className="fa fa-trash"></span>
                        </a>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  displayItemsMobile = () => {
    return (
      <>
        <h3 className="border-top">Recent Attendance</h3>
        {this.state.objs.length === 0 && (
          <p className="text-warning">No result found</p>
        )}
        {this.state.objs
          .slice(
            this.pagesVisited(),
            this.pagesVisited() + this.state.countPerPage
          )
          .map((item) => {
            return (
              <div className="listitem">
                <h6 className="card-header itemtitle">
                  <div className="w-100 d-flex justify-content-end gap-2">
                    <a
                      href="#Delete"
                      title="Delete"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        this.deleteItem(item.id, item.staff?.name);
                      }}
                      className="btn-sm btn-danger float-end"
                    >
                      <span className="fa fa-trash"></span>
                    </a>
                  </div>
                  <div className="row border-bottom text-secondary">
                    <div className="attendance-col">{item.staff_Id}</div>
                    <div className="attendance-col">
                      {AppConfig.Capitalize(item.staff?.name)}
                    </div>
                    <div className="attendance-col">{item.createdOnSimple}</div>
                    <div className="attendance-col">
                      {item.timeIn.split(" ")[1]}
                    </div>
                    <div className="attendance-col">
                      {item.timeOut?.split(" ")[1]}
                    </div>
                    <div className="attendance-col">
                      {AppConfig.Capitalize(item.staff?.company?.name)}
                    </div>
                    <div className="attendance-col">{item.timeIn}</div>
                  </div>
                </h6>
              </div>
            );
          })}
      </>
    );
  };

  render() {
    // let contents = this.state.loadingMain
    //   ? <Loader />
    //   : Branches.renderBranchesTable(this.state.Branches);

    return (
      <div className="">
        <div className="details-box shadow p-2">
          <div className="card-header p-2 mt-2">
            <a href="authorize/Attendance" className="float-end">
              New Attendance
            </a>
          </div>
          <div className="card-body">
            <div className="text-start mt-2">
              {/* <h6 className="control-label">Filter:</h6>
              <div className="row">
                <div className="form-group col-md-3">
                  <label className="control-label">Status:</label>
                  <select
                    className="form-select"
                    onChange={(e) => {
                      this.setState({ status: e.target.value });
                    }}
                    defaultValue={this.state.status}
                  >
                    <option value="-1" selected>
                      All
                    </option>
                    <option value="1">Active</option>
                    <option value="0">Non Active</option>
                  </select>
                </div>
              </div>
              <hr /> */}
              <div className="row">
                <div className="form-group col-md-6 float-start mb-2">
                  <div className="row">
                    <div className="form-group col-md-4">
                      <div className="input-group mb-2">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search by keywords"
                          defaultValue={this.state.SearchValue}
                          onChange={(e) => {
                            this.setSearchValue(e.target.value);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              // Cancel the default action, if needed
                              e.preventDefault();
                              // Trigger the button element with a click
                              this.populateData(this.state.project_Id);
                            }
                          }}
                        />
                        <span
                          className="input-group-text btn btn-primary"
                          onClick={(e) => {
                            this.populateData(this.state.project_Id);
                          }}
                        >
                          <i className=" fa fa-search"></i>
                        </span>
                      </div>
                    </div>
                    <div className="form-group maxw-fit-content">
                      <select
                        className="form-select"
                        onChange={(e) => {
                          this.setState({ project_Id: e.target.value});
                          this.populateAllCompaniesData(e.target.value);
                          this.populateData(e.target.value);
                        }}
                      >
                        {this.state.projects.map(p => <option value={p.id}>{p.name}</option>)}
                      </select>
                    </div>
                  </div>
                  <div className="input-group">
                    <div className="d-inline-flex">
                      <div className="col-md-6">
                        <DatePicker
                          className="form-control "
                          selected={this.state.fromDate}
                          onChange={(e) => {
                            this.setState({ fromDate: e });
                          }}
                          placeholderText="From Date"
                        />
                      </div>
                      <div className="col-md-6">
                        <DatePicker
                          className="form-control"
                          selected={this.state.toDate}
                          onChange={(e) => {
                            this.setState({ toDate: e });
                          }}
                          placeholderText="To Date"
                        />
                        {/* Use fromDate and toDate state values for further processing */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 text-end pe-4">
                  <label className="control-label mw-150 pe-2">Sort By:</label>
                  <select
                    className="mw-150"
                    onChange={(e) => this.setSortBy(e.target.value)}
                  >
                    <option value="id">Id</option>
                    <option value="staff_Id">Staff Id</option>
                    <option value="staff.name">Staff Name</option>
                    <option value="timeIn">Time In</option>
                    <option value="timeOut">Time Out</option>
                  </select>
                  <select
                    className="mw-150"
                    onChange={(e) => this.setSortByOrder(e.target.value)}
                  >
                    <option value="1" selected>
                      Asc
                    </option>
                    <option value="-1">Desc</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="navigation">
              {this.state.loadingMain && <Loader />}
              <div className="border-top pt-4">
                <div className="row justify-content-end">
                  <div className="text-center">
                    <CompanyAttendanceCards
                      companies={this.state.companies}
                      attendances={this.state.objs}
                    />
                  </div>
                  <div className="col-md-4 col-xl-3">
                    <div className="input-group mb-2 mt-2 justify-content-end">
                      <ExportToExcelOrPDF
                        data={this.state.objsToExport}
                        cssClassExcel={"btn btn-light btn-sm"}
                        cssClassPDF={"btn btn-outline-light btn-sm"}
                        filename={`Attendance - ${AppConfig.FormattedDateTime(
                          new Date()
                        )}`}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-block d-md-none">
                {this.displayItemsMobile()}
              </div>
              <div className="d-none d-md-block">{this.displayItems()}</div>
              <hr />
              <div className="react-paginate-nav">
                <CountPerPageDropDown
                  handlePerPageChange={(val) =>
                    this.setState({ countPerPage: val })
                  }
                  perPage={this.state.countPerPage}
                />
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={this.pageCount()}
                  onPageChange={this.changePage}
                  previousLinkClassName={"btn btn-sm btn-primary"}
                  nextLinkClassName={"btn btn-sm btn-primary"}
                  activeClassName={"btn btn-sm btn-primary"}
                  disabledClassName={"btn btn-sm disabled"}
                />
              </div>
            </div>
          </div>
          <div className="text-start">
            <a href="authorize/Attendance" className="">
              Add New Attendance
            </a>
          </div>
        </div>
      </div>
    );
  }
}
