import React from 'react';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import AmiriRegular from '../fonts/Amiri/Amiri-Regular.ttf';
import { toast } from 'react-toastify';
import AppConfig from '../AppConfig';

jsPDF.API.events.push(['addFonts', function() {
  const font = this.loadFile(AmiriRegular);
  this.addFileToVFS('Amiri-Regular.ttf', font);
  this.addFont('Amiri-Regular.ttf', 'Amiri-Regular', 'normal');
}]);

const ExportToExcelOrPDF = ({ data, cssClassExcel, cssClassPDF, filename ='exported_data' }) => {
  // const [selectedFormat, setSelectedFormat] = useState('');
  const exportToPDF = () => {
    if(data.length === 0){
      toast('No data to export');
      return;
    }
    const doc = new jsPDF();

    let tableColumn = Object.keys(data[0]);
    const tableRows = data.map((row) => Object.values(row));

    tableColumn = tableColumn.map(x => x.charAt(0).toUpperCase() + x.slice(1))
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      styles: { font: 'Amiri-Regular' }
    });
    doc.save(`${filename.replace('/', '-')}.pdf`);
  };

  const exportToExcel = () => {
    // Convert your data to an Excel worksheet
    const ws = XLSX.utils.json_to_sheet(data);

    // Create a workbook with the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // Generate a file and download it
    XLSX.writeFile(wb, `${filename.replace('/', '-')}.xlsx`);
  };

  if(AppConfig.getAccessRole() !== "admin" && AppConfig.getAccessRole() !== "staff"){return <></>}

  return (
    <>
      {/* <p>Select export format:</p> */}
      
      <div className="btn-group btn-sm" role="group" aria-label="Export Options">
        <input
          type="radio"
          className="btn-check"
          name="exportOptions"
          id="excelOption"
          autoComplete="off"
          // onChange={exportToExcel}
          onClick={exportToExcel}
          // onChange={() => setSelectedFormat('Excel')}
        />
        <label className={`${cssClassExcel}`} htmlFor="excelOption" title='Export to Excel'>
          {/* Export to Excel */}
          <i className='fa fa-file-excel text-success'></i>
        </label>

        <input
          type="radio"
          className="btn-check"
          name="exportOptions"
          id="pdfOption"
          autoComplete="off"
          // onChange={exportToPDF}
          onClick={exportToPDF}
          // onChange={() => setSelectedFormat('PDF')}
        />
        <label className={`${cssClassPDF}`} htmlFor="pdfOption" title='Export to PDF'>
          {/* Export to PDF */}
          <i className='fa fa-file-pdf text-danger'></i>
        </label>
      </div>
      {/* <button onClick={() => setSelectedFormat('Excel')}>Export to Excel</button>
      <button onClick={() => setSelectedFormat('PDF')}>Export to PDF</button> */}
{/* 
      {selectedFormat === 'Excel' && 
        <button className={cssClass} onClick={exportToExcel}>Export to Excel</button>
      }
      {selectedFormat === 'PDF' && <button onClick={exportToPDF}>Export as PDF</button>} */}
    </>
  );
};

export default ExportToExcelOrPDF;
