import React, { Component } from "react";
import AppConfig from "../../AppConfig";
import { toast } from "react-toastify";
import certificate from "../../images/certified.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import DOMPurify from "dompurify";
import { LoaderFull } from "../LoaderFull";

export class User extends Component {
  constructor(props) {
    super(props);
    this.edit = false;
    if (props.edit !== undefined) this.edit = props.edit;
    this.myRef = React.createRef();
  }
  state = {
    loading: false,
    upLoading: false,
    obj: [],
    selectedValue: null,
    _Id: 0,
    msg: "",
    msgColor: AppConfig.messageColors.success,
  };

  componentDidMount() {
    this.populateData();
  }
  async populateData() {
    var url = "account/profile";
    if (
      this.props._Id !== undefined &&
      this.props._Id !== null &&
      this.edit === false
    )
      // url = "public/user/" + this.props._Id;
    url = "account/users/" + this.props._Id;

    this.setState({
      loading: true,
    });
    var result = await AppConfig.PostData(url, "GET");
     console.log(result)
    if (result["error"] !== undefined) {
      toast.error(result["error"]);
    } else if (result["data"] === undefined) {
      toast.warn("No result found");
      return;
    } else {
      var r = result["data"];
      if (this.props._Id === undefined || this.props._Id === null) {
        if(!r.gender || r.gender === null)
          r.gender = 'male';
        this.setState({
          obj: r,
          _Id: r.id,
          loading: false,
        });
      } else
        this.setState({
          obj: r,
          loading: false,
        });
    }
  }

  async submitData() {
    this.setState({ msgColor: AppConfig.messageColors.warning });
    // if (this.state.obj.names === "") {
    //   this.setState({ msg: "Name is required!" });
    //   return;
    // }

    this.setState({
      upLoading: true,
    });

    var url = "account/user/" + this.state._Id;

    var fd = new FormData();
    fd.append("id", this.state._Id);
    fd.append(
      "first_name",
      this.state.obj.first_name === null ? "" : this.state.obj.first_name
    );
    fd.append(
      "middle_name",
      this.state.obj.middle_name === null ? "" : this.state.obj.middle_name
    );
    fd.append(
      "last_name",
      this.state.obj.last_name === null ? "" : this.state.obj.last_name
    );
    fd.append(
      "phone",
      this.state.obj.phone === null ? "" : this.state.obj.phone
    );
    fd.append(
      "gender",
      this.state.obj.gender === null ? "male" : this.state.obj.gender
    );
    fd.append(
      "address",
      this.state.obj.address === null ? "" : this.state.obj.address
    );
    fd.append(
      "about",
      this.state.obj.about === null
        ? ""
        : DOMPurify.sanitize(this.state.obj.about)
    );
    if (this.state.selectedValue !== null)
      fd.append("file", this.state.selectedValue);
    var resultFile = await AppConfig.PostData(url, "PUT", fd, true);
    // console.log(resultFile)
    if (resultFile["error"] !== undefined) {
      toast(resultFile["error"]);
    } else {
      if (resultFile["data"] !== undefined) {
        toast("Updated successfully");
        setTimeout(() => {
          this.populateData();
        }, 2000);
      }
    }
    this.setState({
      upLoading: false,
    });
  }

  handleDetailsChange = (input, e) => {
    var obj = this.state.obj;
    if (input === "about") {
      // console.log(e)
      obj[input] = e;
      return;
    } else if (
      e.target.type === "text" ||
      e.target.type === "date" ||
      e.target.type === "select-one"
    ) {
      obj[input] = e.target.value;
      this.setState({ obj });
      return;
    }
    // alert(e.target.type)
    var fileName = e.target.value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (
      extFile === "png" ||
      extFile === "bmp" ||
      extFile === "jpg" ||
      extFile === "jpeg"
    ) {
      this.setState({ selectedValue: e.target.files[0] });
    } else {
      alert("Only image files are allowed!");
    }
  };

  renderData(obj) {
    return (
      <div className="row">
        <fieldset
          disabled={!this.edit}
          ref={this.myRef}
          className="col-md-4 card card-light shadow mb-4"
        >
          {this.edit === false && (
            <div className="profile-header">
              <div className="mx-auto d-flex text-center">
                {obj.path !== null && (
                  <div className="img-profile">
                    <img
                      className="img-thumbnail img-rounded hover-zoom"
                      alt={obj.names + " profile"}
                      src={
                        AppConfig.baseAPIUrl + "account/profile_images/" + obj.id
                      }
                    />
                  </div>
                )}
                <div className="cert-group">
                  {obj.certification === 1 && (
                    <React.Fragment>
                      <img
                        src={certificate}
                        alt="certification icon"
                        title="certified"
                        className="cert-icon"
                      />
                      <span className="text-warning">Certified</span>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="form-group">
              <label className="form-label">Username</label>
              <input
                type="text"
                className="form-control"
                placeholder="Username"
                aria-label="Name"
                aria-describedby="basic-addon1"
                defaultValue={obj.name}
                disabled
                // onChange={e=>this.handleDetailsChange('name', e)}
              />
            </div>
            <div className="form-group">
              <label className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                aria-label="Email"
                aria-describedby="basic-addon1"
                defaultValue={obj.email}
                disabled
                // onChange={e=>this.handleDetailsChange('email', e)}
              />
            </div>
            <div className="form-group">
              <label className="form-label">First Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="First Name"
                aria-label="first_name"
                aria-describedby="basic-addon1"
                defaultValue={obj.first_name}
                onChange={(e) => this.handleDetailsChange("first_name", e)}
              />
            </div>
            <div className="form-group">
              <label className="form-label">Middle Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Middle Name"
                aria-label="middle_name"
                aria-describedby="basic-addon1"
                defaultValue={obj.middle_name}
                onChange={(e) => this.handleDetailsChange("middle_name", e)}
              />
            </div>
            <div className="form-group">
              <label className="form-label">Last Name</label>
              <input
                type="text"
                className="form-control"
                placeholder="Last Name"
                aria-label="last_name"
                aria-describedby="basic-addon1"
                defaultValue={obj.last_name}
                onChange={(e) => this.handleDetailsChange("last_name", e)}
              />
            </div>
            <div className="form-group">
              <label className="form-label">Phone</label>
              <input
                type="tel"
                className="form-control"
                placeholder="phone"
                aria-label="phone"
                aria-describedby="basic-addon1"
                defaultValue={obj.phone}
                onChange={(e) => this.handleDetailsChange("phone", e)}
              />
            </div>
            <div className="form-group">
              <label className="form-label">Address</label>
              <input
                type="text"
                className="form-control"
                placeholder="Address"
                aria-label="Address"
                aria-describedby="basic-addon1"
                defaultValue={obj.address}
                onChange={(e) => this.handleDetailsChange("address", e)}
              />
            </div>
            <div className="form-group">
              <label className="form-label">Gender</label>
              <select className="form-select form-control"
                placeholder="Gender"
                aria-label="Gender"
                aria-describedby="basic-addon1"
                defaultValue={obj.gender}
                onChange={(e) => this.handleDetailsChange("gender", e)}>
                  <option value={'male'}>Male</option>
                  <option value={'female'}>Female</option>
                </select>
            </div>
            {this.edit !== false && (<>
            <div className="form-group">
              <label className="control-label">
                {obj.filename === null && "Attach Profile Picture: "}
                {obj.filename !== null && "Change Profile Picture: "}
              </label>
              <input
                type="file"
                className={`form-control border-success`}
                accept="image/png, image/bmp, image/jpeg"
                title="Select a Profile Picture"
                // defaultValue={this.state.selectedValue}
                onChange={(e) =>
                  this.handleDetailsChange("selectedValue", e)
                }
              />
              <label type="text" className="text-primary" disabled>
                No Image Set
              </label>
            </div>
            {obj.path !== null && (
              <div className="img-profile col-md-6">
                <img
                  className="img-thumbnail img-rounded hover-zoom"
                  alt={obj.names + " profile"}
                  src={
                    AppConfig.baseAPIUrl +
                    "account/profile_images/" +
                    obj.id
                  }
                />
              </div>)}</>
            )}
            {/* <div className="col-6">
              <label className="form-label">Active</label>
              <div className="input-group mb-3">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    {obj.status && (
                      <i className="fa fa-check text-success"></i>
                    )}
                    {!obj.status && (
                      <i className="fa fa-close text-danger"></i>
                    )}
                  </span>
                </div>
              </div>
            </div> */}
            <div className="col-6">
              <div className="input-group cert-group">
                {obj.certification === 1 && (
                  <>
                    <img
                      src={certificate}
                      alt="certification icon"
                      title="certified"
                      className="cert-icon"
                    />
                    <span className="text-warning">Certified</span>
                  </>
                )}
              </div>
            </div>
            {/* { (!this.edit && this.state._Id !== undefined) && */}
            {/* } */}
          </div>
        </fieldset>
        <div className="col-md-8">
          {this.edit === true && (
            <>
              <label className="control-label">About: </label>
              <ReactQuill
                required
                className="textarea"
                placeholder="Enter some details about you"
                value={obj.about}
                onChange={(e) => this.handleDetailsChange("about", e)}
              ></ReactQuill>
            </>
          )}
          {this.edit !== true && obj.about !== null && obj.about !== '' && (
            <>
              <label className="control-label">About: </label>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(obj.about),
                  // __html: obj.description,
                }}
              ></div>
            </>
          )}
        </div>
      </div>
    );
  }

  render() {
    let contents = this.state.loading ? (
      <LoaderFull />
    ) : (
      this.renderData(this.state.obj)
    );

    const get_buttons = () => {
      if (this.edit === false) return <></>;

      return (
        <>
          {!this.state.upLoading && (
            <button
              className="btn btn-success col-md-2"
              onClick={(e) => {
                this.submitData();
              }}
            >
              <i className="fa fa-save me-2"></i>
              <span>Save Changes</span>
            </button>
          )}
          {this.state.upLoading && (
            <button disabled className="btn btn-primary col-md-2">
              <i className="fa fa-spinner fa-spin me-2"></i>
              Saving Changes...
            </button>
          )}
        </>
      );
    };
    return (
      <div>
        <div
          className="row justify-content-center"
          aria-labelledby="tabelLabel"
        >
          <div className="details-box col-md-6 card shadow p-0">
            <h4 className="card-header text-warning border-bottom">
              {this.edit !== false && "Update User Profile"}
              {this.edit === false &&
                `${AppConfig.Capitalize(this.state.obj?.name)} - Profile`}
            </h4>
            <div className="w-100 d-flex justify-content-end p-2 gap-2">
              {this.edit !== false && (
                <a
                  href={`user/${this.state._Id}`}
                  target="_blank"
                  className="btn btn-sm btn-primary"
                  rel="noreferrer"
                >
                  <i className="fa fa-eye"></i> Preview
                </a>
              )}
              {get_buttons()}
            </div>
            <div className="p-4">
              {this.edit !== false && (
                <p className="border-bottom">Enter user details below.</p>
              )}
              {contents}
            </div>
            <div className="card-footer text-center">{get_buttons()}</div>
          </div>
        </div>
      </div>
    );
  }
}
