import React, { useState, useEffect } from 'react';
import AppConfig from '../../AppConfig';
import authService from '../api-authorization/AuthorizeService';
import { toast } from 'react-toastify';
// import { Navigate } from 'react-router-dom';
// import auth from '../auth/auth';


export const ResetComponent  = () =>  {
    
    const [email, setEmail]= useState("");
    const [password, setPassword]= useState("");
    const [token, setToken]= useState("");
    const [cpassword, setCPassword]= useState("");
    const [isLoading, setIsLoading]= useState(false);
    const [isTokenSent, setIsTokenSent]= useState(false);
    const [passwordType, setPasswordType]= useState('password');

    useEffect(() => {
        
    }, []);

    const validatePassword = (e) => {
        var validated =  true;
        var errM = "" ;
        if(e.value.length < 8){
            validated = false;
            errM += " up to 8 characters"
        }
        if(!/\d/.test(e.value)){
            validated = false;
            errM += errM === "" ? "at least one digit" : ", at least one digit"
        }
        if(!/[a-z]/.test(e.value)){
            validated = false;
            errM += errM === "" ? "at least one lower case" : ", one lower case"
        }
        if(!/[A-Z]/.test(e.value)){
            validated = false;
            errM += errM === "" ? "at least one upper case" : ", one upper case"
        }
        if(/[a-zA-Z0-9]{8,}/.test(e.value)){
            validated = false;
            errM += errM === "" ? "special character" : ", and have a special character"
        }

        e.style.borderColor = "green";
        if(!validated){
            toast.warn("Password must contain " + errM);
            e.style.borderColor = "red";
        }
        return validated;
    }
    const handleSubmit = (btn) =>{
        try {
            if(!email.includes("@")){
                toast.error("A valid email is required.");
                return;
            }
            (async () => {
                setIsLoading(true);                
                var result = await AppConfig.PostData(`account/reset?email=${email}`, 'GET');
                console.log(result);
                if(result["error"] !== null && result["error"] !== undefined){
                    toast.error(result["error"]);
                }else{
                    toast("If you've provided registered e-mail, you should get recovery e-mail shortly.")
                    if(result["status"] !== null && result["status"] !== undefined){
                        if(result["status"] === "success" || result["status"] === true || 
                            result["status"] === "true"){
                            setIsTokenSent(true);
                        }
                    }
                }
                setIsLoading(false);
            })();
        } catch (error) {
            toast.error(error.toString().replace("TypeError: ", ""));
        }
    }
    const handleSubmitNew = (btn) =>{
        if(!email.includes("@")){
            toast.error("A valid email is required.");
            return;
        }
        if(cpassword !== password){
            toast.error("Password does not match.");
            return;
        }

        if(/[a-zA-Z0-9]{8,}/.test(password)){
            toast.error("Invalid password.")
            return;
        }

        const userData = JSON.stringify({
            "email": email,
            "token": token,
            "password": password,
        });
        try {
            (async () => {
                setIsLoading(true);                
                var result = await AppConfig.PostData(`account/resetNew`, 'POST', userData);
                console.log(result);
                if(result["error"] !== null && result["error"] !== undefined){
                    toast.error(result["error"]);
                }else{
                    toast(result['message'])
                }
                setIsLoading(false);
                return;
            })();
        } catch (error) {
            toast.error(error.toString().replace("TypeError: ", ""));
        }
    }

    const togglePWD = (e) => {
        // toggle the type attribute
        const pwd = document.getElementById("Password");
        if(pwd==null) return;
        const newType = passwordType === "password" ? "text" : "password";
        setPasswordType(newType);
        // toggle the eye icon
        e.className = passwordType === "password" ? 'fa fa-eye-slash' : 'fa fa-eye';
    };
    return ( 
        <React.Fragment>
            <form name='frm' id='frm' className='col-md-4 col-lg-3'>
                {
                    !isTokenSent &&
                    <div className='form-group'>
                        <label className='control-label lbl'>Email</label>
                        <div className="input-group mb-3">
                            <span className="input-group-text" id="basic-addon1">
                                <i className='fa fa-envelope'></i>
                            </span>
                            <input required className='form-control' type='email' 
                                placeholder='Enter Email'
                                defaultValue={email} autoFocus
                                onChange={e => setEmail(e.target.value)}
                                aria-describedby="basic-addon1"
                                />
                        </div>
                    </div>
                }
                {
                    isTokenSent &&
                    <div>
                        <div className='form-group'>
                            <label className='control-label lbl'>Token</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className='fa fa-user'></i>
                                </span>
                                <input required className='form-control' type='email' 
                                    placeholder='Enter Token Sent to your email'
                                    defaultValue={token} autoFocus
                                    onChange={e => setToken(e.target.value)}
                                    aria-describedby="basic-addon1"
                                    />
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className='control-label lbl'>Password</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1">
                                    <i className='fa fa-key'></i>
                                </span>
                                <input required className='form-control' type={passwordType} 
                                    placeholder='Enter Password'
                                    defaultValue={password} id="pwd"
                                    onChange={e => {setPassword(e.target.value); validatePassword(e.target)}}
                                    aria-describedby="basic-addon1" data-toggle="password"
                                    />
                                <span className="input-group-text"><i className="fa fa-eye-slash" 
                                    onClick={e=>{togglePWD(e.target)}}></i></span>
                            </div>
                        </div>
                        <div className='form-group'>
                            <label className='control-label lbl'>Confirm Password</label>
                            <div className="input-group mb-3">
                                <span className="input-group-text" id="basic-addon1"><i className='fa fa-key'></i></span>
                                <input required className='form-control' type={passwordType} 
                                    placeholder='Re-type Your Password'
                                    defaultValue={cpassword}
                                    onChange={e => setCPassword(e.target.value)}
                                    aria-describedby="basic-addon1"
                                    />
                            </div>
                        </div>
                    </div>
                }
                <hr className='hr'/>
                <div className='form-group text-center'>
                    {isLoading &&
                        <button 
                            type='button'
                            className='btn btn-primary btn-5'>
                            <i className='fa fa-spinner fa-spin'></i> Submiting...
                        </button>
                    }
                    {!isLoading && !isTokenSent &&
                        <button 
                            className='btn btn-primary btn-5'
                            onClick={e => {
                                e.preventDefault();
                                return handleSubmit(this)
                            }}>Reset</button>
                    }
                    {!isLoading && isTokenSent &&
                        <button 
                            className='btn btn-primary btn-5'
                            onClick={e => {
                                e.preventDefault();
                                return handleSubmitNew(this)
                            }}>Change My Password</button>
                    }
                    <div><a href='authorize/login'>Login instead?</a></div>
                </div>
            </form>
        </React.Fragment>
    );
}