import React, { useState } from "react";
import AppConfig from "../../AppConfig";

const CompanyAttendanceCards = ({ companies, attendances }) => {
  const [show, setShow] = useState(false);

  // Function to count attendances for each company
  const countAttendances = (companyId) => {
    return attendances.filter(
      (attendance) => attendance.staff?.company_Id === companyId
    ).length;
  };
  
  if(AppConfig.getAccessRole() !== "admin"){return <></>}

  return (
    <div className="">
      <div className="d-flex justify-content-end">
        <button className="btn btn-primary me-2" onClick={() => setShow(!show)}>
          <i className={`me-2 fa fa-eye${show ? '-slash' : ''}`}></i> 
          {!show && 'Show Attendances By Company'}
          {show && 'Hide Attendances By Company'}
        </button>
      </div>
      {show && (
        <div
          style={{
            display: "flex",
            overflowX: "auto",
            whiteSpace: "nowrap",
            width: "100%",
            padding: "10px",
          }}
          className="justify-content-center gap-2"
        >
          {companies.map((company) => (
            <div
              style={{
                display: "flex",
                flexWrap: "nowrap",
                width: "15em",
                borderLeft: "0.4em solid",
                borderRight: "0.4em solid",
                minWidth: "fit-content",
              }}
              key={company.id}
              className="circle card p-4 pb-2 pt-2 border-success text-primary"
            >
              <h4 className="text-success">{company.name}</h4>
              <h2>{countAttendances(company.id)}</h2>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CompanyAttendanceCards;
