import React from "react";

export function Loader({ textcolor = "text-white", message }) {
  // const [color, setColor] = useState(textcolor === undefined ? 'text-white' : textcolor);

  return (
    <div
      className={
        "row justify-content-center pt-5 " + textcolor
      }
    >
      <div className="text-center">
        <h3>Please Wait</h3>
        <h4 className='text-center'>{message}</h4>
        <p>
          <i className="h3 fa fa-spinner fa-spin"></i>
        </p>
      </div>
    </div>
  );
}
