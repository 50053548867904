import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AppConfig from "../../AppConfig";

// First, create the thunk
const fetchUser = createAsyncThunk("user/fetchUser", async () => {
  try {
    // Retrieve state from localStorage

    var result = await AppConfig.PostData(
      `account/ping`,
      "GET"
    );
    if (result) {
      let user = await result.json();
      // console.log(user);
      return user;
    } else {
      return null;
    }
  } catch (error) {
    return [...error.message];
  }
});

const initialState = {
  user: null,
  isAuthenticated: false,
  error: "",
  loading: "idle", // 'idle' | 'pending' | 'succeeded' | 'failed'
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = [...action.payload];
      state.error = null;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = "loading";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.loading = "succeeded";
        if (action.payload !== null) {
          state.user = action.payload.data;
          state.isAuthenticated = true;
        } else {
          // localStorage.removeItem("access_token");
          // localStorage.removeItem("role");
          // localStorage.removeItem("email");
          // localStorage.removeItem("name");
          // localStorage.removeItem("id");
          state.isAuthenticated = false;
          state.error = "Invalid login!";
        }
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = "failed";
        state.isAuthenticated = false;
        state.error = action.error.message;
      });
  },
});

// Action creators are generated for each case reducer function
export const { setError, setUser } = userSlice.actions;
export { fetchUser };

export default userSlice.reducer;
