import React, { useState, useEffect } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";

const BarcodeScanner = ({ processImage, isInUse = false }) => {
  const [selectedDeviceId, setSelectedDeviceId] = useState("");
  const [deviceList, setDeviceList] = useState([]);
  const [componentKey, setComponentKey] = useState(0);
  const [data, setData] = useState("Not running!");
  const [pauseStream, setPauseStream] = useState(true);
  const [remountKey, setRemountKey] = useState(0);

  // useEffect(() => {
  //   // setData(!pauseStream ? "Scanning..." : "Not running!");
  // }, [pauseStream]);

  useEffect(() => {
    // Fetch available devices when component mounts
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        setDeviceList(videoDevices);
        if (videoDevices.length > 0) {
          setSelectedDeviceId(videoDevices[0].deviceId);
        }
      })
      .catch((error) => {
        console.error("Error fetching devices:", error);
      });
  }, []);

  const handleDeviceChange = (event) => {
    setSelectedDeviceId(event.target.value);
    setComponentKey((prevKey) => prevKey + 1);
  };

  const videoConstraints = {
    facingMode: "environment",
    deviceId: selectedDeviceId ? { exact: selectedDeviceId } : undefined,
  };

  return (
    <div>
      <select className="form-select mb-2" onChange={handleDeviceChange} value={selectedDeviceId}>
        <option value="">Select Camera</option>
        {deviceList.map((device) => (
          <option key={device.deviceId} value={device.deviceId}>
            {device.label || `Camera ${device.deviceId}`}
          </option>
        ))}
      </select>
      {!pauseStream && (
        <>
          <BarcodeScannerComponent
            // key={componentKey}
            key={`scanner-${remountKey}`} // Use remountKey to force remount
            width={"100%"}
            height={500}
            delay={1000}
            stopStream={pauseStream}
            constraints={videoConstraints}
            onUpdate={(err, result) => {
              // console.log(err);
              if (result) {
                const val = Number(result.text);
                setData(val);
                if (!isInUse) {
                  setPauseStream(true);
                  processImage(val);
                }
              } else {
                // setData(!pauseStream ? "Scanning..." : 'Not running!');
                setData("Scanning...");
              }
            }}
          />
          <p>{data}</p>
        </>
      )}
      <button
        className="btn btn-sm btn-primary"
        onClick={() => {
          setPauseStream(!pauseStream);
          if (!pauseStream) setRemountKey((prevKey) => prevKey + 1); // Increment remountKey to force remount
        }}
      >
        {pauseStream && "Start Auto Scan"}
        {!pauseStream && "Stop Auto Scan"}
      </button>
    </div>
  );
};

export default BarcodeScanner;
