import { UserManager, WebStorageStateStore } from "oidc-client";
import AppConfig from "../../AppConfig";
import { ApplicationPaths, ApplicationName } from "./ApiAuthorizationConstants";

export class AuthorizeService {
  _callbacks = [];
  _nextSubscriptionId = 0;
  _user = null;
  _isAuthenticated = false;

  // By default pop ups are disabled because they don't work properly on Edge.
  // If you want to enable pop up authentication simply set this flag to false.
  _popUpDisabled = true;

  async isAuthenticated(role = []) {
    const token = AppConfig.getAccessToken();
    const userRole = AppConfig.getAccessRole();
    // console.log(token)
    if (token === null || token === "") return false;
    if (role !== undefined && role.length > 0  && role !== null && !role.includes(userRole)) return false;

    // Retrieve the saved date from localStorage
    const savedDateStr = sessionStorage.getItem("last_login");
    if (savedDateStr) {
      const savedDate = new Date(JSON.parse(savedDateStr));
      const currentDate = new Date();

      // Calculate the time difference in milliseconds
      const timeDifference = currentDate - savedDate;

      // Convert milliseconds to hours
      const hoursPassed = Math.floor(timeDifference / (1000 * 60 * 60));

      //   console.log(`Hours passed since last save: ${hoursPassed} hours`);
      if (hoursPassed <= 24) return true;
    }

    var result = await AppConfig.PostData(
      `account/ping`,
      "GET",
      null,
      false,
      false
    );
    if (result.status === 200) {
      const result1 = (await result.json());
      if(result1.data !== undefined){
        this._user = result1.data;
        this._isAuthenticated = true;
        sessionStorage.setItem('last_login', JSON.stringify(new Date()));
        // this.signIn();
        return true;
      }
      return false;

    } else {
      // localStorage.removeItem('access_token');
      // this._isAuthenticated = false;
      return false;
    }

    // const user = await this.getUser();
    // return !!user;
  }

  async getUser() {
    if (!this._isAuthenticated) return null;

    return this._user;
  }

  async signIn() {
    // await this.ensureUserManagerInitialized();
    this._isAuthenticated = true;
    this.notifySubscribers();
  }

  async signInMain() {
    await this.ensureUserManagerInitialized();
    this._isAuthenticated = true;
    this.notifySubscribers();
  }

  // We try to sign out the user in two different ways:
  // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
  //    Pop-Up blocker or the user has disabled PopUps.
  // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
  //    post logout redirect flow.
  async signOut(state) {
    // await this.ensureUserManagerInitialized();

    try {
      var result = await AppConfig.PostData(`account/logout`, "POST");
      console.log(result);
      if (result["status"] !== "success") {
        this.completeSignOut();
        return true;
      } else {
        localStorage.removeItem("access_token");
        localStorage.removeItem("role");
        localStorage.removeItem("email");
        localStorage.removeItem("name");
        localStorage.removeItem("id");
        this._isAuthenticated = false;
        window.location.href = "/";
        return AuthenticationResultStatus.Success;
        // return false;
      }
    } catch (popupSignOutError) {
      console.log("Popup signout error: ", popupSignOutError);
    }
  }

  async completeSignOut(url) {
    await this.ensureUserManagerInitialized();
    try {
      const response = await this.userManager.signoutCallback(url);
      this._isAuthenticated = false;
      this.notifySubscribers();
      return this.success(response && response.data);
    } catch (error) {
      console.log(`There was an error trying to log out '${error}'.`);
      return this.error(error);
    }
  }

  subscribe(callback) {
    this._callbacks.push({
      callback,
      subscription: this._nextSubscriptionId++,
    });
    return this._nextSubscriptionId - 1;
  }

  unsubscribe(subscriptionId) {
    const subscriptionIndex = this._callbacks
      .map((element, index) =>
        element.subscription === subscriptionId
          ? { found: true, index }
          : { found: false }
      )
      .filter((element) => element.found === true);
    if (subscriptionIndex.length !== 1) {
      throw new Error(
        `Found an invalid number of subscriptions ${subscriptionIndex.length}`
      );
    }

    this._callbacks.splice(subscriptionIndex[0].index, 1);
  }

  notifySubscribers() {
    for (let i = 0; i < this._callbacks.length; i++) {
      const callback = this._callbacks[i].callback;
      callback();
    }
  }

  createArguments(state) {
    return { useReplaceToNavigate: true, data: state };
  }

  error(message) {
    return { status: AuthenticationResultStatus.Fail, message };
  }

  success(state) {
    return { status: AuthenticationResultStatus.Success, state };
  }

  redirect() {
    return { status: AuthenticationResultStatus.Redirect };
  }

  async ensureUserManagerInitialized() {
    if (this.userManager !== undefined) {
      return;
    }

    let settings = {
      loadUserInfo: true,
      userStore: new WebStorageStateStore({
        store: localStorage,
      }),
      authority: AppConfig.baseAPIUrl,
      client_id: "JAVASCRIPT_CLIENT_ID",
      redirect_uri: ApplicationPaths.Login,
      response_type: "id_token token",
      response_mode: "fragment",
      scope: "openid profile", // add other scopes here
      post_logout_redirect_uri: ApplicationPaths.LogOut,
    };
    settings.automaticSilentRenew = true;
    settings.includeIdTokenInSilentRenew = true;
    settings.userStore = new WebStorageStateStore({
      prefix: ApplicationName,
    });

    this.userManager = new UserManager(settings);

    this.userManager.events.addUserSignedOut(async () => {
      await this.userManager.removeUser();
      this.updateState(undefined);
    });
  }

  static get instance() {
    return authService;
  }
}

const authService = new AuthorizeService();

export default authService;

export const AuthenticationResultStatus = {
  Redirect: "redirect",
  Success: "success",
  Fail: "fail",
};
