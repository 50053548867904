import React, { Component } from "react";
import AppConfig from "../../AppConfig";
import { toast } from "react-toastify";
import { LoaderFull } from "../LoaderFull";
import ReactPaginate from "react-paginate";
import { Loader } from "../Loader";
import CustomAlert from "../custom/custom_alert";
import ExportToExcelOrPDF from "../ExportToExcelOrPDF";
import CompanyAttendanceCards from "./CompanyAttendanceCards";
import { CountPerPageDropDown } from "../CountPerPageDropDown";
import BarcodeScanner from "../Scanner/BarcodeScanner";
import Html5QrcodePlugin from "../Scanner/Html5QrcodeScannerPlugin";

export class Attendance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      isWaiting: false,
      upLoading: false,
      loadingMain: false,
      companies: [],
      projects: [], project_Id: 0,
      objs: [],
      objsToExport: [],
      _Id: "",
      msg: "",
      prevData: "",
      audio: new Audio('beep-08b.mp3'),
      role: AppConfig.getAccessRole(),
      msgColor: AppConfig.messageColors.success,
      pageNumber: 0,
      countPerPage: 25,
    };

    this.myRef = React.createRef(); // Creating a ref
  }

  componentDidMount() {
    // this.populateData();
    // this.populateAllCompaniesData();
    this.populateProjectsData();
  }

  async submitData() {
    if (this.state._Id === "") {
      this.setState({ msg: "Id is required!" });
      this.myRef.current.focus();
      return;
    }

    this.upLoadData(this.state._Id);
  }

  async upLoadData(id) {
    // if (AppConfig.getAccessRole() !== "admin") return;

    this.setState({
      upLoading: true,
    });

    var url = "attendances";
    var method = "POST";

    const body = JSON.stringify({
      staff_Id: id,
    });

    var result = await AppConfig.PostData(url, method, body);
    this.setState({
      upLoading: false,
    });
    this.setState({ _Id: "" });
    if (result["exception"] !== undefined || result["error"] !== undefined) {
      toast.error(result["error"]);
    } else if (
      result["result"] === "success" ||
      result["result"] === true ||
      result["data"] !== undefined
    ) {
      // toast(AppConfig.messages.success);
      this.setState({ msg: AppConfig.messages.success });
      this.setState({ msgColor: "success" });
      this.populateData(this.state.project_Id);
    } else {
      toast.error(result["message"]);
      // this.setState({ msg: result["message"] });
    }
    setTimeout(() => this.myRef.current.focus(), 1000);
  }

  async populateData(project_Id) {
    AppConfig.setProjectId(project_Id);
    this.setState({ loadingMain: true });
    var url =
      `attendances?` +
      new URLSearchParams({
        status: true,
        today: true,
      });
    var result = await AppConfig.PostData(url, "GET");
    //  console.log(result)
    if (result["error"] !== undefined) {
      toast(result["status"]);
    } else {
      const newObjs = [];
      const newObjsToExport = [];
      result["data"].forEach((prop) => {
        var newItem = { ...prop };
        if(prop.staff?.project_Id === Number(project_Id)){
          newObjs.push(newItem);
          newObjsToExport.push({
            Id: prop.staff_Id,
            Name: prop.staff?.name,
            Company: prop.staff?.company?.name,
            Date: prop.createdOnSimple,
            "Time In": prop.timeIn.split(" ")[1],
            "Time Out": prop.timeOut?.split(" ")[1],
            "Scan In": prop.timeIn,
          });
        }
      });
      newObjs.sort(AppConfig.dynamicSort('timeIn', 1));
      newObjsToExport.sort(AppConfig.dynamicSort('Time In', 1));

      this.setState({
        objsToExport: newObjsToExport,
      });
      this.setState({
        objs: newObjs,
      });
    }
    this.setState({
      loadingMain: false,
    });
  }

  async populateAllCompaniesData(project_Id) {
    AppConfig.setProjectId(project_Id);
    var url =
      `companies?` +
      new URLSearchParams({
        status: true,
      });

    var result = await AppConfig.PostData(url, "GET");
    //  console.log(result)
    if (result["error"] !== undefined) {
      // toast(result["status"]);
    } else {
      // if (result["data"].length === 0) {
      //   toast.warn("No company was found");
      // }

      this.setState({
        companies: result["data"],
      });
    }
  }

  async populateProjectsData() {
    this.setState({ loadingMain: true });
    var url = `projects`

    var result = await AppConfig.PostData(url, "GET");
    //  console.log(result)
    if (result["error"] !== undefined) {
      toast(result["status"]);
    } else {
      this.setState({
        projects: result["data"],
      });
      if (result["data"].length === 0) {
        toast.warn("No projects was found");
      }else{
        this.setState({ project_Id: result['data'][0].id});
        AppConfig.setProjectId(result['data'][0].id);
        this.populateAllCompaniesData(result['data'][0].id);
        this.populateData(result['data'][0].id);
      }
    }
    this.setState({
      loadingMain: false,
    });
  }

  handleDetailsChange = (e) => {
    this.processInputData(e.target.value)
  };

  processInputData = (text, processImmediately = false) => {
    let val = text;
    val = val.replace(/\D/g, "").replace('.', '');
    const num = Number(val);
    if(num > 0)
      this.setState({ _Id:  num});
    else
      this.setState({ _Id:  ''});

    // console.log('Value is:');
    // console.log(e.target.value);
    // console.log(num);
    if (val.length === 12 && !processImmediately) {
      this.setState({isWaiting: true});
      setTimeout(()=>{
        if(this.state.isWaiting)
        this.upLoadData(num);
        this.setState({isWaiting: false});
      }, 700)
    }
    else if (val.length === 13 || processImmediately) {
      this.setState({isWaiting: false});
      this.upLoadData(num);
    }
    else if (val.length > 13) {
      this.setState({ msg: `Error: '${text}' has Unexpected digit length of ${text.length}!` });
      setTimeout(()=>
        this.setState({ _Id: '' }), 500
      )
    }
  };

  processInputDataMobile = (num) => {
    if(this.state.prevData !== num){
      this.state.audio.play();
      this.setState({prevData: num})
      setTimeout(()=>this.setState({prevData: 0}), 5000);
      this.upLoadData(num);
    }
  };

  pagesVisited = () => {
    return this.state.pageNumber * this.state.countPerPage;
  };

  pageCount = () => {
    return Math.ceil(this.state.objs.length / this.state.countPerPage);
  };

  changePage = ({ selected }) => {
    this.setState({ pageNumber: selected });
  };

  async deleteItem(_id, _title) {
    if (AppConfig.getAccessRole() !== "admin") return;
    var answer = window.confirm("Delete attendance for " + _title + "?");
    if (answer) {
      this.setState({ loading: true });
      var result = await AppConfig.PostData(`attendances/` + _id, "DELETE");
      if (result["exception"] !== undefined || result["error"] !== undefined) {
        toast.error(result["error"]);
      } else if (result["result"] !== "success" && result["result"] !== true) {
        toast(result["message"]);
      } else {
        toast(result["message"]);
        setTimeout(() => {
          var items = this.state.objs.filter((x) => {
            return x.id !== _id;
          });
          var items2 = this.state.objsToExport.filter((x) => {
            return x.id !== _id;
          });
          this.setState({ objs: items });
          this.setState({ objsToExport: items2 });
        }, 1000);
      }
      this.setState({
        loading: false,
      });
    }
  }

  displayItems = () => {
    return (
      <>
        <h4 className="border-top pt-4">Recent Attendance</h4>
        {this.state.objs.length === 0 && (
          <p className="text-warning">No result found</p>
        )}

        <div class="table-responsive col-md-12">
          <table className="table-hover table table-light mt-4">
            <thead>
              <tr className="text-secondary">
                <th>ID Number</th>
                <th>Name</th>
                <th>Company</th>
                <th>Date</th>
                <th>Time In</th>
                <th>Time Out</th>
                <th>Scan In</th>
                {this.state.role === "admin" && <th>Actions</th>}
              </tr>
            </thead>
            <tbody>
              {this.state.objs
                .slice(
                  this.pagesVisited(),
                  this.pagesVisited() + this.state.countPerPage
                )
                .map((item) => {
                  return (
                    <tr>
                      <td>{item.staff_Id}</td>
                      <td>{AppConfig.Capitalize(item.staff?.name)}</td>
                      <td>{AppConfig.Capitalize(item.staff?.company?.name)}</td>
                      <td>{item.createdOnSimple}</td>
                      <td>{item.timeIn.split(" ")[1]}</td>
                      <td>{item.timeOut?.split(" ")[1]}</td>
                      <td>{item.timeIn}</td>
                      {this.state.role === "admin" && (
                        <td>
                          <a
                            href="#Delete"
                            title="Delete"
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              this.deleteItem(item.id, item.staff?.name);
                            }}
                            className="btn-sm btn-danger"
                          >
                            <span className="fa fa-trash"></span>
                          </a>
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </>
    );
  };

  displayItemsMobile = () => {
    return (
      <>
        <h3 className="border-top">Recent Attendance</h3>
        {this.state.objs.length === 0 && (
          <p className="text-warning">No result found</p>
        )}
        {this.state.objs
          .slice(
            this.pagesVisited(),
            this.pagesVisited() + this.state.countPerPage
          )
          .map((item) => {
            return (
              <div className="listitem">
                <h6 className="card-header itemtitle">
                  <div className="w-100 d-flex justify-content-end gap-2">
                    <a
                      href="#Delete"
                      title="Delete"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        this.deleteItem(item.id, item.staff?.name);
                      }}
                      className="btn-sm btn-danger float-end"
                    >
                      <span className="fa fa-trash"></span>
                    </a>
                  </div>
                  <div className="row border-bottom text-secondary">
                    <div className="attendance-col">{item.staff_Id}</div>
                    <div className="attendance-col">
                      {AppConfig.Capitalize(item.staff?.name)}
                    </div>
                    <div className="attendance-col">
                      {item.timeIn.split(" ")[1]}
                    </div>
                    <div className="attendance-col">
                      {item.timeOut?.split(" ")[1]}
                    </div>
                    <div className="attendance-col">
                      {AppConfig.Capitalize(item.staff?.company?.name)}
                    </div>
                  </div>
                </h6>
              </div>
            );
          })}
      </>
    );
  };

  renderData() {
    return (
      <fieldset id="fieldset" className="">
        <div className="form-group text-center">
          <label className="form-label">ID Number</label>
          <input
            autoFocus={true}
            ref={this.myRef}
            type="text"
            className="form-control"
            placeholder="ID Number"
            aria-label="ID Number"
            aria-describedby="basic-addon1"
            disabled={this.state.upLoading}
            value={this.state._Id}
            onChange={(e) => this.handleDetailsChange(e)}
          />
        </div>

        {/* <BarcodeScanner processImage={this.processInputData} isInUse={this.state.isWaiting || this.state.upLoading}/> */}
        {/* <BarcodeScanner processImage={this.processInputDataMobile} isInUse={this.state.upLoading}/> */}
        <Html5QrcodePlugin processImage={this.processInputDataMobile} isInUse={this.state.upLoading}/>
      </fieldset>
    );
  }

  render() {
    let contents = this.state.loading ? <LoaderFull /> : this.renderData();

    return (
      <div>
        <div
          className="row justify-content-center"
          aria-labelledby="tabelLabel"
        >
          <div className="details-box col-md-6 card shadow p-0 text-center">
            <h4 className="card-header text-warning">
              {(this.state.role === 'admin' || AppConfig.getAccessRole() === "staff") && <div className="w-100 d-flex justify-content-end gap-2">
                <label>
                  <a
                    href="authorize/attendances"
                    className="btn btn-sm btn-primary"
                  >
                    <i className="fa fa-close"></i>
                  </a>
                </label>
              </div>}
              <div className="">Attendance</div>
            </h4>
            <p className="border-bottom"></p>
            <div className="p-4 row justify-content-center">
              <div className="col-md-3">
                {contents}
                {this.state.msg !== "" && (
                  <CustomAlert
                    type={this.state.msgColor}
                    msg={this.state.msg}
                  />
                )}
                <div className="card-footer pe-0 ps-0">
                  {this.state.upLoading && (
                    <button disabled className="btn btn-primary">
                      <i className="fa fa-spinner fa-spin me-2"></i>
                      Saving Attendance...
                    </button>
                  )}
                </div>
              </div>
            </div>

            <div className="navigation">
              {this.state.loadingMain && <Loader />}
              {(this.state.role === 'admin' || AppConfig.getAccessRole() === "staff") && <div className="border-top pt-4">
                <div className="row justify-content-end">
                  <div className="">
                    <CompanyAttendanceCards
                      companies={this.state.companies}
                      attendances={this.state.objs}
                    />
                  </div>
                  <div className="form-group maxw-fit-content mt-2 me-2">
                    <select
                      className="form-select form-control"
                      onChange={(e) => {
                        this.setState({ project_Id: e.target.value});
                        this.populateAllCompaniesData(e.target.value);
                        this.populateData(e.target.value);
                      }}
                    >
                      {this.state.projects.map(p => <option value={p.id}>{p.name}</option>)}
                    </select>
                  </div>
                  <div className="maxw-fit-content justify-content-end d-flex">
                    <div className="input-group mb-2 mt-2 justify-content-end pe-2" style={{maxWidth: 'max-content'}}>
                      <ExportToExcelOrPDF
                        data={this.state.objsToExport}
                        cssClassExcel={"btn btn-light btn-sm"}
                        cssClassPDF={"btn btn-outline-light btn-sm"}
                        filename={`Attendance - ${AppConfig.FormattedDateTime(
                          new Date()
                        )}`}
                      />
                    </div>
                  </div>
                </div>
              </div>}

              <div className="d-block d-md-none">
                {this.displayItemsMobile()}
              </div>
              <div className="d-none d-md-block">{this.displayItems()}</div>

              <hr />
              <div className="react-paginate-nav">
                <CountPerPageDropDown handlePerPageChange={(val)=>this.setState({countPerPage: val})} perPage={this.state.countPerPage}/>
                <ReactPaginate
                  previousLabel={"Previous"}
                  nextLabel={"Next"}
                  pageCount={this.pageCount()}
                  onPageChange={this.changePage}
                  previousLinkClassName={"btn btn-sm btn-primary"}
                  nextLinkClassName={"btn btn-sm btn-primary"}
                  activeClassName={"btn btn-sm btn-primary"}
                  disabledClassName={"btn btn-sm disabled"}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
