import React from "react";

const ProjectDataGroup = ({ users, projects, companies, staffs }) => {
  return (
    <div className="col-md-12 text-secondary p-0">
      <h3 className="projects-header text-center p-2" hidden>Projects</h3>
      <div className="w-100 p-2 bg-dark-green">
        <div className="row justify-content-center gap-2">
          {projects.map((p) => (
            <div
              key={p.id}
              className="card text-center p-5 d-flex align-items-center maxw-fit-content projects"
            >
              <div className="circle p-4 pb-0 project-box">
                <p title="Project">{p.name}</p>
              </div>
              <img src="/arrow-down.png" alt="arrow" className="mx-auto"/>
              <div className="d-block d-lg-flex gap-2">
                {companies
                  .filter((x) => x.project_Id === p.id)
                  .map((c) => (
                    <div key={c.id} className="col gap-2 mb-4">
                      <div
                        style={{
                          minWidth: "fit-content",
                        }}
                        className="circle p-0 pt-2 company-box text-center"
                      >
                        <p title="company"
                          style={{
                            minWidth: "10em",
                            minHeight: "1em",
                          }}
                        >
                          {c.name}
                        </p>
                      </div>
                      <img src="/arrow-down.png" alt="arrow" className="mx-auto"/>
                      <div className="circle p-2 ps-4 pe-4 pb-0 staff-box m-auto maxw-fit-content">
                        <p title="staffs">
                          {
                            staffs.filter(
                              (x) =>
                                x.project_Id === p.id && x.company_Id === c.id
                            ).length
                          }
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProjectDataGroup;
