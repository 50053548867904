import React, { useEffect, useState } from "react";
import AppConfig from "../AppConfig";
import { toast } from "react-toastify";
import TimezoneSelect from "react-timezone-select";

const TimezoneSelector = ({setRegion}) => {
  const [oldTimezone, setOldTimezone] = useState("");
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [upLoading, setUpLoading] = useState(false);

  useEffect(() => {
    LoadData();
  }, []);

  const LoadData = async () => {
    setUpLoading(true);
    var url = "settings/timezone";

    var result = await AppConfig.PostData(url, "GET");
    if (result["error"] !== undefined) {
      toast(result["error"]);
    } else {
      if (result["message"] !== undefined) {
        toast(result["message"]);
      }
      if (result["value"] !== undefined) {
        setOldTimezone(result.value);
        setRegion(result.value);
      }
    }
    setUpLoading(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedTimezone === "") {
      toast("Select a timezone!");
      return;
    }
    setUpLoading(true);
    var url = "settings/timezone";

    var resultFile = await AppConfig.PostData(
      url,
      "POST",
      JSON.stringify(selectedTimezone)
    );
    if (resultFile["error"] !== undefined) {
      toast(resultFile["error"]);
    } else {
      if (resultFile["message"] !== undefined) {
        toast(resultFile["message"]);
      }
    }
    setUpLoading(false);
  };

  return (
    <div className="card p-2 text-secondary">
      <h3>Select Timezone</h3>
      <form onSubmit={handleSubmit}>
        {oldTimezone !== "" && <label>{oldTimezone}</label>}
        <TimezoneSelect
          value={selectedTimezone}
          onChange={setSelectedTimezone}
        />
        {!upLoading && (
          <button type="submit" className="btn btn-primary mt-2">
            Save changes
          </button>
        )}
        {upLoading && (
          <button className="btn btn-primary">
            <i className="fa fa-spin fa-spinner"></i>
          </button>
        )}
      </form>
    </div>
  );
};

export default TimezoneSelector;
